import { useSearchParams } from "react-router-dom";
import "../style.css"

function CompPage() {
    const copyEmail = () => {
        navigator.clipboard.writeText("info@smoulasoutez.cz");
        alert("E-mail byl zkopírován do schránky.")
    }

    const copyNumber = () => {
        navigator.clipboard.writeText("+420228222698");
        alert("Telefonní číslo bylo zkopírováno do schránky.")
    }

    return ( 
        <div className="compPage">
            <h1>Tato stránka byla vytvořena pro účely <a href="https://smoulasoutez.cz">soutěže ŠMouLa.</a></h1>
            <br/>
            <p>SK Záhonek je fiktivní sportovní klub vytvořen pro účely úlohy do soutěže ŠMouLa v roce 2025.</p>
            <br/>
            <p>V případě potřeby nás kontaktujte na <button onClick={copyEmail}>info@smoulasoutez.cz</button> nebo na telefonním čísle <button onClick={copyNumber}>+420 228 222 698.</button></p>
        </div>
     );
}

export default CompPage;