import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import "./style.css"
import Homepage from "./pages/Homepage";
import ReservationPage from "./pages/ReservationPage";
import CompPage from "./pages/CompPage";
import PriceList from "./pages/PriceList";
import ContactPage from "./pages/ContactPage";
import Footer from "./components/Footer"

function App() {
  return (
    <BrowserRouter>
    <header>
        <div className="logo"><Link to="/">SK Záhonek</Link></div>
        <nav>
            <Link to="/cenik">Ceník</Link>
            <Link to="/rezervace">Rezervace</Link>
            <Link to="/kontakt">Kontakt</Link>
        </nav>
    </header>

    <Routes>
      <Route path="/" element={<Homepage/>}/>
      <Route path="/cenik" element={<PriceList/>}/>
      <Route path="/rezervace" element={<ReservationPage/>}/>
      <Route path="/kontakt" element={<ContactPage/>}/>
      <Route path="/smoula" element={<CompPage/>}/>
      <Route path="*" element={<h1 style={{height: "100vh"}}>Tato stránka neexistuje.</h1>}/>
    </Routes>

    <Footer/>

    </BrowserRouter>
  );
}

export default App;
