import { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { reservationData } from "../assets/reservations";

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

function ReservationPage() {
    const formatDate = (d: Date) => {
        const dd = d.getDate().toString().padStart(2, '0');
        const mm = (d.getMonth() + 1).toString().padStart(2, '0');
        const yyyy = d.getFullYear();

        return `${dd}. ${mm}. ${yyyy}`;
    };

    const [value, onChange] = useState<Value>(new Date());

    const filtered = () => {
        const result = reservationData.filter((r) => {
            return new Date(r.date).setHours(0, 0, 0) === (value as Date).setHours(0, 0, 0);
        })[0];

        if (!result) {
            return <h1>V tento den nejsou žádné rezervace.</h1>;
        }

        return result.reservations
            .sort((a, b) => Number(a?.time.split(":")[0]) - Number(b?.time.split(":")[0]))
            .map((r, index) => (
                <div className="reservationCard" key={index}>
                    <h2>{r?.time}</h2>
                    <h3>{`Jméno: ${r?.name}`}</h3>
                    <h3 dangerouslySetInnerHTML={{ __html: r.court }} />
                </div>
            ));
    };

    return (
        <div className="reservationPage">
            <h1>Kalendář rezervací</h1>

            <Calendar onChange={onChange} value={value} />

            <h2 style={{ marginTop: "1vh" }}>Rezervace na den {formatDate(value as Date)}</h2>
            <div className="cardContainer">
                {reservationData.length && filtered()}
            </div>
        </div>
    );
}

export default ReservationPage;
