const ContactPage: React.FC = () => {
    const copyTennisEmail = () => {
        navigator.clipboard.writeText("info@sk-zahonek.fun");
        alert("E-mail byl zkopírován do schránky.")
    }

    const copyTennisNumber = () => {
        navigator.clipboard.writeText("+420910114587");
        alert("Telefonní číslo bylo zkopírováno do schránky.")
    }

  return (
    <div style={{ padding: "20px", maxWidth: "600px", margin: "0 auto" }} className="contactPage">
      <h2>Kontaktujte nás</h2>
      <p>
        Pokud máte jakékoliv dotazy, neváhejte nás kontaktovat prostřednictvím následujících údajů
        nebo nám zanechte zprávu prostřednictvím formuláře níže.
      </p>
      <div style={{ marginBottom: "20px" }}>
        <h3>Kontakt</h3>
        <p><strong>Email:</strong> <button onClick={copyTennisEmail}>info@sk-zahonek.fun</button></p>
        <p><strong>Telefon:</strong> <button onClick={copyTennisNumber}>+420 910 114 587</button></p>
        <p><strong>Adresa:</strong> Poštovní 1762, 431 04 Šumplerk</p>
      </div>
    </div>
  );
};

export default ContactPage;
