export const reservationData = [
    {
        "date": "2024-01-01",
        "reservations": [
            {
                "name": "Juraj Michn\u00edk",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Franti\u0161ek Jel\u00ednek",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ivana Ka\u0161parov\u00e1",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "Sergiy Koudela",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Nikola B\u00ednov\u00e1",
                "time": "11:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-01-02",
        "reservations": [
            {
                "name": "Pavel Such\u00fd",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Kate\u0159ina \u0160im\u00e1kov\u00e1",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Martina Jun",
                "time": "13:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-01-03",
        "reservations": [
            {
                "name": "Lenka \u0160vecov\u00e1",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Hynek Ve\u010de\u0159a",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jind\u0159ich Bud\u00edn",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ji\u0159ina Drmolov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Tom\u00e1\u0161 \u0160t\u011bp\u00e1n",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "Marek \u0160koln\u00edk",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Quoc Hung V\u00e1vra",
                "time": "18:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-01-04",
        "reservations": [
            {
                "name": "Petr Br\u016f\u017eek",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Roman Polinsk\u00fd",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Milo\u0161 Vit\u00e1sek",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Mat\u011bj Suda",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Michal Faiereisl",
                "time": "08:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-01-05",
        "reservations": [
            {
                "name": "Ludmila Ruck\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Vojt\u011bch Brul\u00edk",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ladislav Tr\u00e1vn\u00ed\u010dek",
                "time": "11:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-01-06",
        "reservations": [
            {
                "name": "Renata Hrochov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Miroslav \u0160udoma",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Mat\u011bj Suda",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Radim Hor\u00e1k",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ji\u0159ina Drmolov\u00e1",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Milena Ridajov\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Stefania Davisov\u00e1",
                "time": "14:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-01-07",
        "reservations": [
            {
                "name": "Lenka \u0160vecov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Mat\u011bj Suda",
                "time": "16:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-01-08",
        "reservations": [
            {
                "name": "Krist\u00fdna Bajgarov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ivanka B\u00edlkov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Natalia \u0160ruba\u0159ov\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "V\u011bra Makov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "V\u011bra Makov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Tereza Kvasni\u010dkov\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ren\u00e1ta Foksov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-01-09",
        "reservations": [
            {
                "name": "Kamila Vejsadov\u00e1",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Lenka \u0160vecov\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Metod\u011bj Selinger",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jana Kratochv\u00edlov\u00e1",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Eva Va\u0148ov\u00e1",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jarmila St\u00fctzov\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Martin V\u0161et\u00fd\u010dek",
                "time": "08:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-01-10",
        "reservations": [
            {
                "name": "Jakub Bla\u017eek",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Drahu\u0161e Wro\u017eynov\u00e1",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Dimitrios Ku\u010dera",
                "time": "16:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-01-11",
        "reservations": [
            {
                "name": "Irena Votavov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Petr Br\u016f\u017eek",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Martin V\u0161et\u00fd\u010dek",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Emil Holub",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "Anton\u00edn Kutil",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Franti\u0161ek Jel\u00ednek",
                "time": "12:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-01-12",
        "reservations": [
            {
                "name": "Ji\u0159\u00ed \u010cadil",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Martina Jun",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Jaroslava Pasekov\u00e1",
                "time": "15:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-01-13",
        "reservations": [
            {
                "name": "Franti\u0161ek Jel\u00ednek",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Alan Kohout",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jakub Bla\u017eek",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Franti\u0161ek Jel\u00ednek",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Dana \u010celedov\u00e1",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Juraj Michn\u00edk",
                "time": "13:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-01-14",
        "reservations": [
            {
                "name": "Jaroslava Pasekov\u00e1",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Karel Havl\u00ed\u010dek",
                "time": "15:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-01-15",
        "reservations": [
            {
                "name": "Drahu\u0161e Wro\u017eynov\u00e1",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Lucie Kop\u0159ivov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Iveta Nowakov\u00e1",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Robert Maier",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Josef Lutsak",
                "time": "08:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-01-16",
        "reservations": [
            {
                "name": "\u0160t\u011bp\u00e1nka Profantov\u00e1",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Colin Pla\u010dek",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Milo\u0161 Vit\u00e1sek",
                "time": "12:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-01-17",
        "reservations": [
            {
                "name": "Kamila Vejsadov\u00e1",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Tom\u00e1\u0161 \u0160t\u011bp\u00e1n",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "David Rou\u0161ar",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Pavel Such\u00fd",
                "time": "16:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-01-18",
        "reservations": [
            {
                "name": "Ond\u0159ej Robl",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Adam Smol\u00edk",
                "time": "10:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-01-19",
        "reservations": [
            {
                "name": "Zden\u011bk Kobliha",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Alexandr Bruzl",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Marie Samcov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jitka Pruckov\u00e1",
                "time": "18:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-01-20",
        "reservations": [
            {
                "name": "Alan Kohout",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Zden\u011bk Kobliha",
                "time": "10:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-01-21",
        "reservations": [
            {
                "name": "Zuzana Ku\u010derov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Helena Kohoutov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-01-22",
        "reservations": [
            {
                "name": "Miroslav \u0160udoma",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ani Mileva K\u0159emenov\u00e1",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Kristina Star\u00e1",
                "time": "11:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-01-23",
        "reservations": [
            {
                "name": "\u0160t\u011bp\u00e1nka Profantov\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Alexandr Bruzl",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Drahom\u00edra Pa\u017adziorov\u00e1",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jitka Pruckov\u00e1",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Dana \u010celedov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-01-24",
        "reservations": [
            {
                "name": "Jind\u0159ich Bud\u00edn",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Jaroslava Pasekov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Karel Havl\u00ed\u010dek",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Anna Noskov\u00e1",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Karel Havl\u00ed\u010dek",
                "time": "14:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-01-25",
        "reservations": [
            {
                "name": "Libor Joh\u00e1nek",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jaroslav Milda",
                "time": "11:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-01-26",
        "reservations": [
            {
                "name": "Colin Pla\u010dek",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Stanislav \u010c\u00ed\u017eek",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ji\u0159\u00ed \u010cadil",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Alexandr Bruzl",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ji\u0159\u00ed \u010cadil",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Karel Havl\u00ed\u010dek",
                "time": "11:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-01-27",
        "reservations": [
            {
                "name": "Robert Maier",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Lenka \u0160vecov\u00e1",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Romana Brou\u0161kov\u00e1",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Vladislav Do\u010dkal",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Dana \u010celedov\u00e1",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Mat\u011bj Suda",
                "time": "11:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-01-28",
        "reservations": [
            {
                "name": "V\u00e1clav Graja",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Jaroslav Milda",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Martina B\u011bhunkov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ludmila Ruck\u00e1",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Vladislav Do\u010dkal",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Miroslav \u0160udoma",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ivanka B\u00edlkov\u00e1",
                "time": "18:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-01-29",
        "reservations": [
            {
                "name": "Emil Holub",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ji\u0159\u00ed \u010cadil",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Marie Samcov\u00e1",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jarmila St\u00fctzov\u00e1",
                "time": "13:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-01-30",
        "reservations": [
            {
                "name": "Alena Leitnerov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Petra Vorlov\u00e1",
                "time": "11:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-01-31",
        "reservations": [
            {
                "name": "Radim Hor\u00e1k",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Franti\u0161ek Jel\u00ednek",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Anton\u00edn Kutil",
                "time": "14:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-02-01",
        "reservations": [
            {
                "name": "Roman Polinsk\u00fd",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ludmila Ruck\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Franti\u0161ek Jel\u00ednek",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Metod\u011bj Selinger",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Michal Faiereisl",
                "time": "13:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-02-02",
        "reservations": [
            {
                "name": "Martina Jun",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "Lubom\u00edr Kl\u00edma",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Kamila Vejsadov\u00e1",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Michaela Krej\u010dov\u00e1",
                "time": "09:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-02-03",
        "reservations": [
            {
                "name": "Dana \u010celedov\u00e1",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ji\u0159ina Drmolov\u00e1",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Renata Hrochov\u00e1",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Lubom\u00edr Kl\u00edma",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Alena Leitnerov\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-02-04",
        "reservations": [
            {
                "name": "Vladim\u00edr Fatka",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ludmila Ruck\u00e1",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Lenka \u0160vecov\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-02-05",
        "reservations": [
            {
                "name": "Libu\u0161e Poulov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jarmila St\u00fctzov\u00e1",
                "time": "13:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-02-06",
        "reservations": [
            {
                "name": "Lenka \u0160vecov\u00e1",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ji\u0159ina Drmolov\u00e1",
                "time": "08:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-02-07",
        "reservations": [
            {
                "name": "Alena Leitnerov\u00e1",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Hynek Ve\u010de\u0159a",
                "time": "11:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-02-08",
        "reservations": [
            {
                "name": "Tom\u00e1\u0161 \u0160t\u011bp\u00e1n",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Stefania Davisov\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Nikola B\u00ednov\u00e1",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "V\u00e1clava Rul\u00edkov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ji\u0159\u00ed \u010cadil",
                "time": "17:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-02-09",
        "reservations": [
            {
                "name": "Vladim\u00edr Fatka",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Kate\u0159ina \u0160im\u00e1kov\u00e1",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Miroslav \u0160udoma",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Jaroslav Milda",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Stanislav \u010c\u00ed\u017eek",
                "time": "10:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-02-10",
        "reservations": [
            {
                "name": "Ivana Ka\u0161parov\u00e1",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Jind\u0159ich Bud\u00edn",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Daniel Vrba",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Tereza Kvasni\u010dkov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Drahu\u0161e Wro\u017eynov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jana Kratochv\u00edlov\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-02-11",
        "reservations": [
            {
                "name": "Anna Noskov\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Helena Kohoutov\u00e1",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ji\u0159\u00ed \u010cadil",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Nikola B\u00ednov\u00e1",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Michal Faiereisl",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "V\u00e1clav Graja",
                "time": "10:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-02-12",
        "reservations": [
            {
                "name": "Alena Leitnerov\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "V\u00e1clav Graja",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Nikola B\u00ednov\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ladislav Tr\u00e1vn\u00ed\u010dek",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Karel Havl\u00ed\u010dek",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Kate\u0159ina \u0160im\u00e1kov\u00e1",
                "time": "17:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-02-13",
        "reservations": [
            {
                "name": "Michal Faiereisl",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Drahu\u0161e Wro\u017eynov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Roman Polinsk\u00fd",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Alan Kohout",
                "time": "18:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-02-14",
        "reservations": [
            {
                "name": "Miroslav \u0160udoma",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Kamila Vejsadov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Anna Noskov\u00e1",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Nad\u011b\u017eda Chalupov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "David Rou\u0161ar",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Anna Noskov\u00e1",
                "time": "09:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-02-15",
        "reservations": [
            {
                "name": "Kate\u0159ina \u0160im\u00e1kov\u00e1",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Colin Pla\u010dek",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "Vojt\u011bch Brul\u00edk",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Hynek Ve\u010de\u0159a",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Radka Pastrnkov\u00e1",
                "time": "18:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-02-16",
        "reservations": [
            {
                "name": "Vladislav Do\u010dkal",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "V\u011bra Makov\u00e1",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Kamila Vejsadov\u00e1",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Vlastimil Hl\u00e1vka",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Anna Noskov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-02-17",
        "reservations": [
            {
                "name": "Ladislav Tr\u00e1vn\u00ed\u010dek",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Natalia \u0160ruba\u0159ov\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Petr Br\u016f\u017eek",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jind\u0159ich Bud\u00edn",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Karel Havl\u00ed\u010dek",
                "time": "17:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-02-18",
        "reservations": [
            {
                "name": "Jind\u0159ich Bud\u00edn",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Zuzana Ku\u010derov\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Tereza Kvasni\u010dkov\u00e1",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Alan Kohout",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Luk\u00e1\u0161 Trnka",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Hana \u0160ikulov\u00e1",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Barbara Souhradov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-02-19",
        "reservations": [
            {
                "name": "Ond\u0159ej Robl",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ani Mileva K\u0159emenov\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Vojt\u011bch Brul\u00edk",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Stefania Davisov\u00e1",
                "time": "14:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-02-20",
        "reservations": [
            {
                "name": "Ond\u0159ej Robl",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "\u0160t\u011bp\u00e1nka Profantov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Milo\u0161 Vit\u00e1sek",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Lubom\u00edr Kl\u00edma",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Alan Kohout",
                "time": "15:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-02-21",
        "reservations": [
            {
                "name": "Ludmila Ruck\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Irena Votavov\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Alan Kohout",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jarmila St\u00fctzov\u00e1",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Renata Hrochov\u00e1",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Bo\u017eena Herltov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jakub Bla\u017eek",
                "time": "09:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-02-22",
        "reservations": [
            {
                "name": "Jaroslava Pasekov\u00e1",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Kamila Vejsadov\u00e1",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Alan Kohout",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ji\u0159\u00ed \u010cadil",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Kate\u0159ina \u0160im\u00e1kov\u00e1",
                "time": "11:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-02-23",
        "reservations": [
            {
                "name": "Libu\u0161e Poulov\u00e1",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Marek \u0160koln\u00edk",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Vladislav Do\u010dkal",
                "time": "14:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-02-24",
        "reservations": [
            {
                "name": "Radka Pastrnkov\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Nikola B\u00ednov\u00e1",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Nade\u017eda Barto\u0148ov\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Dagmar \u010cajkov\u00e1",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Irena Votavov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Josef Lutsak",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Libu\u0161e Poulov\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-02-25",
        "reservations": [
            {
                "name": "Milo\u0161 Vit\u00e1sek",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Vladislav Do\u010dkal",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Kristina Star\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ivanka B\u00edlkov\u00e1",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Hynek Ve\u010de\u0159a",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Lubom\u00edr Kl\u00edma",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Vojt\u011bch Brul\u00edk",
                "time": "14:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-02-26",
        "reservations": [
            {
                "name": "Emil Holub",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jaroslava Pasekov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jan Skrbek",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Petra Vorlov\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Alena Leitnerov\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-02-27",
        "reservations": [
            {
                "name": "Vojt\u011bch Brul\u00edk",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Barbora Z\u00e1vadov\u00e1",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Stefania Davisov\u00e1",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Stanislav \u010c\u00ed\u017eek",
                "time": "09:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-02-28",
        "reservations": [
            {
                "name": "Barbora Z\u00e1vadov\u00e1",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Alena Leitnerov\u00e1",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Colin Pla\u010dek",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Krist\u00fdna Bajgarov\u00e1",
                "time": "16:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-02-29",
        "reservations": [
            {
                "name": "Luk\u00e1\u0161 Trnka",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Sergiy Koudela",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Emil Holub",
                "time": "08:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-03-01",
        "reservations": [
            {
                "name": "Emil Holub",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ivana Ka\u0161parov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-03-02",
        "reservations": [
            {
                "name": "Stanislav \u010c\u00ed\u017eek",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Quoc Hung V\u00e1vra",
                "time": "08:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-03-03",
        "reservations": [
            {
                "name": "Adam Smol\u00edk",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Miroslav \u0160udoma",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Pavel Such\u00fd",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Jakub Bla\u017eek",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Barbora Z\u00e1vadov\u00e1",
                "time": "15:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-03-04",
        "reservations": [
            {
                "name": "Hana \u0160ikulov\u00e1",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Eva Va\u0148ov\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Zuzana Ku\u010derov\u00e1",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Dagmar \u010cajkov\u00e1",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Veronika Tomkov\u00e1",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ani Mileva K\u0159emenov\u00e1",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Colin Pla\u010dek",
                "time": "15:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-03-05",
        "reservations": [
            {
                "name": "Eva Va\u0148ov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Lubom\u00edr Kl\u00edma",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "Nad\u011b\u017eda Chalupov\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ji\u0159\u00ed \u010cadil",
                "time": "18:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-03-06",
        "reservations": [
            {
                "name": "Jaroslava Pasekov\u00e1",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Natalia \u0160ruba\u0159ov\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Romana Brou\u0161kov\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-03-07",
        "reservations": [
            {
                "name": "Pavel Such\u00fd",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ivana Ka\u0161parov\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Romana Brou\u0161kov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Dimitrios Ku\u010dera",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Dana \u010celedov\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Karel Havl\u00ed\u010dek",
                "time": "12:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-03-08",
        "reservations": [
            {
                "name": "Anna Noskov\u00e1",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Marek \u0160koln\u00edk",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Marek \u0160koln\u00edk",
                "time": "18:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-03-09",
        "reservations": [
            {
                "name": "Metod\u011bj Selinger",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Metod\u011bj Selinger",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Alena Leitnerov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "David Rou\u0161ar",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Lenka \u0160vecov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-03-10",
        "reservations": [
            {
                "name": "Quoc Hung V\u00e1vra",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Mat\u011bj Suda",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Josef Lutsak",
                "time": "13:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-03-11",
        "reservations": [
            {
                "name": "Libor Joh\u00e1nek",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Martin V\u0161et\u00fd\u010dek",
                "time": "08:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-03-12",
        "reservations": [
            {
                "name": "Milan B\u00edlek",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Marie Samcov\u00e1",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Dagmar \u010cajkov\u00e1",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "V\u011bra Makov\u00e1",
                "time": "15:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-03-13",
        "reservations": [
            {
                "name": "Michaela Krej\u010dov\u00e1",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ivanka B\u00edlkov\u00e1",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Martina Jun",
                "time": "17:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-03-14",
        "reservations": [
            {
                "name": "Bo\u017eena Herltov\u00e1",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Milena Ridajov\u00e1",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Marek \u0160koln\u00edk",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Mat\u011bj Suda",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "V\u00e1clava Rul\u00edkov\u00e1",
                "time": "11:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-03-15",
        "reservations": [
            {
                "name": "Jana Kratochv\u00edlov\u00e1",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Vlasta Hrabov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-03-16",
        "reservations": [
            {
                "name": "Roman Polinsk\u00fd",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Romana Brou\u0161kov\u00e1",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Irena Votavov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Martin V\u0161et\u00fd\u010dek",
                "time": "10:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-03-17",
        "reservations": [
            {
                "name": "Ji\u0159ina Drmolov\u00e1",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jaroslava Pasekov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Kate\u0159ina \u0160im\u00e1kov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Pavel Such\u00fd",
                "time": "11:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-03-18",
        "reservations": [
            {
                "name": "V\u00e1clava Rul\u00edkov\u00e1",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jan Skrbek",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Franti\u0161ek Jel\u00ednek",
                "time": "16:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-03-19",
        "reservations": [
            {
                "name": "Jan Skrbek",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Daniel Vrba",
                "time": "10:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-03-20",
        "reservations": [
            {
                "name": "Pavel Such\u00fd",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ji\u0159ina Drmolov\u00e1",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Renata Hrochov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Alena Leitnerov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-03-21",
        "reservations": [
            {
                "name": "Barbora Z\u00e1vadov\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jitka Pruckov\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Krist\u00fdna Bajgarov\u00e1",
                "time": "13:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-03-22",
        "reservations": [
            {
                "name": "Pavel Such\u00fd",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Zden\u011bk Kobliha",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jind\u0159ich Bud\u00edn",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Dimitrios Ku\u010dera",
                "time": "08:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-03-23",
        "reservations": [
            {
                "name": "Bo\u017eena Herltov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Lucie Kop\u0159ivov\u00e1",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Marie Samcov\u00e1",
                "time": "15:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-03-24",
        "reservations": [
            {
                "name": "Barbora Z\u00e1vadov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Kate\u0159ina \u0160im\u00e1kov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Lucie Kop\u0159ivov\u00e1",
                "time": "10:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-03-25",
        "reservations": [
            {
                "name": "\u0160t\u011bp\u00e1nka Profantov\u00e1",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Martin V\u0161et\u00fd\u010dek",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Vojt\u011bch Brul\u00edk",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Irena Votavov\u00e1",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ludmila Ruck\u00e1",
                "time": "09:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-03-26",
        "reservations": [
            {
                "name": "Hana \u0160ikulov\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Michal Faiereisl",
                "time": "11:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-03-27",
        "reservations": [
            {
                "name": "Hynek Ve\u010de\u0159a",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Anton\u00edn Kutil",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ladislav Tr\u00e1vn\u00ed\u010dek",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ludmila Ruck\u00e1",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Krist\u00fdna Bajgarov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Tom\u00e1\u0161 \u0160t\u011bp\u00e1n",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Barbora Z\u00e1vadov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-03-28",
        "reservations": [
            {
                "name": "Michaela Krej\u010dov\u00e1",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Alexandr Bruzl",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ond\u0159ej Robl",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Natalia \u0160ruba\u0159ov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jan Skrbek",
                "time": "12:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-03-29",
        "reservations": [
            {
                "name": "Ji\u0159\u00ed \u010cadil",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Colin Pla\u010dek",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Daniel Vrba",
                "time": "11:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-03-30",
        "reservations": [
            {
                "name": "Milo\u0161 Vit\u00e1sek",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Kristina Star\u00e1",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Mat\u011bj Suda",
                "time": "10:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-03-31",
        "reservations": [
            {
                "name": "Dagmar \u010cajkov\u00e1",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Kristina Star\u00e1",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Stanislav \u010c\u00ed\u017eek",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Helena Kohoutov\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Martina Jun",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Lucie Kop\u0159ivov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Nad\u011b\u017eda Chalupov\u00e1",
                "time": "08:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-04-01",
        "reservations": [
            {
                "name": "Ji\u0159\u00ed \u010cadil",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Luk\u00e1\u0161 Trnka",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Tom\u00e1\u0161 \u0160t\u011bp\u00e1n",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Eva Va\u0148ov\u00e1",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Alan Kohout",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "V\u00e1clav Graja",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ivana Ka\u0161parov\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-04-02",
        "reservations": [
            {
                "name": "Metod\u011bj Selinger",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Drahom\u00edra Pa\u017adziorov\u00e1",
                "time": "18:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-04-03",
        "reservations": [
            {
                "name": "Natalia \u0160ruba\u0159ov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Hynek Ve\u010de\u0159a",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Adam Smol\u00edk",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Milo\u0161 Vit\u00e1sek",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Irena Votavov\u00e1",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Tereza Kvasni\u010dkov\u00e1",
                "time": "11:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-04-04",
        "reservations": [
            {
                "name": "Ivana Ka\u0161parov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ani Mileva K\u0159emenov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jind\u0159ich Bud\u00edn",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Nad\u011b\u017eda Chalupov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Natalia \u0160ruba\u0159ov\u00e1",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jan Skrbek",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Lubom\u00edr Kl\u00edma",
                "time": "11:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-04-05",
        "reservations": [
            {
                "name": "Radim Hor\u00e1k",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Zden\u011bk Kobliha",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Roman Polinsk\u00fd",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Milena Ridajov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Nade\u017eda Barto\u0148ov\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Franti\u0161ek Jel\u00ednek",
                "time": "12:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-04-06",
        "reservations": [
            {
                "name": "Petra Vorlov\u00e1",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "\u0160t\u011bp\u00e1nka Profantov\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-04-07",
        "reservations": [
            {
                "name": "Drahu\u0161e Wro\u017eynov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Libor Joh\u00e1nek",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Alexandr Bruzl",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Kamila Vejsadov\u00e1",
                "time": "18:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-04-08",
        "reservations": [
            {
                "name": "Colin Pla\u010dek",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Natalia \u0160ruba\u0159ov\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Anton\u00edn Kutil",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Anton\u00edn Kutil",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Drahom\u00edra Pa\u017adziorov\u00e1",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "Vladim\u00edr Fatka",
                "time": "16:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-04-09",
        "reservations": [
            {
                "name": "Ren\u00e1ta Foksov\u00e1",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Milan B\u00edlek",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Michal Faiereisl",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Marie Samcov\u00e1",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Adam Smol\u00edk",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Mat\u011bj Suda",
                "time": "16:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-04-10",
        "reservations": [
            {
                "name": "Bo\u017eena Herltov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Kristina Star\u00e1",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Milena Ridajov\u00e1",
                "time": "18:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-04-11",
        "reservations": [
            {
                "name": "Nade\u017eda Barto\u0148ov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ren\u00e1ta Foksov\u00e1",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Roman Polinsk\u00fd",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "Nikola B\u00ednov\u00e1",
                "time": "10:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-04-12",
        "reservations": [
            {
                "name": "Dana \u010celedov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Libor Joh\u00e1nek",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Marie Samcov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Veronika Tomkov\u00e1",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "David Rou\u0161ar",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Bo\u017eena Herltov\u00e1",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Veronika Tomkov\u00e1",
                "time": "15:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-04-13",
        "reservations": [
            {
                "name": "Petr Br\u016f\u017eek",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Alena Leitnerov\u00e1",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Petr Br\u016f\u017eek",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Nade\u017eda Barto\u0148ov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Mat\u011bj Suda",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "Milena Ridajov\u00e1",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Marek \u0160koln\u00edk",
                "time": "08:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-04-14",
        "reservations": [
            {
                "name": "Michal Faiereisl",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "V\u011bra Makov\u00e1",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Juraj Michn\u00edk",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ani Mileva K\u0159emenov\u00e1",
                "time": "18:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-04-15",
        "reservations": [
            {
                "name": "Josef Lutsak",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Josef Lutsak",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Lubom\u00edr Kl\u00edma",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Vlastimil Hl\u00e1vka",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Kristina Star\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Drahom\u00edra Pa\u017adziorov\u00e1",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Sergiy Koudela",
                "time": "11:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-04-16",
        "reservations": [
            {
                "name": "Petr Br\u016f\u017eek",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Martina B\u011bhunkov\u00e1",
                "time": "15:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-04-17",
        "reservations": [
            {
                "name": "Nad\u011b\u017eda Chalupov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Lucie Kop\u0159ivov\u00e1",
                "time": "13:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-04-18",
        "reservations": [
            {
                "name": "Ji\u0159\u00ed \u010cadil",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "Jitka Pruckov\u00e1",
                "time": "09:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-04-19",
        "reservations": [
            {
                "name": "Lucie Kop\u0159ivov\u00e1",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Robert Maier",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Daniel Vrba",
                "time": "17:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-04-20",
        "reservations": [
            {
                "name": "Alan Kohout",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Roman Polinsk\u00fd",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Zuzana Ku\u010derov\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-04-21",
        "reservations": [
            {
                "name": "V\u00e1clava Rul\u00edkov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ladislav Tr\u00e1vn\u00ed\u010dek",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ond\u0159ej Robl",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Krist\u00fdna Bajgarov\u00e1",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Tom\u00e1\u0161 \u0160t\u011bp\u00e1n",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Libu\u0161e Poulov\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-04-22",
        "reservations": [
            {
                "name": "Adam Smol\u00edk",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "David Rou\u0161ar",
                "time": "18:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-04-23",
        "reservations": [
            {
                "name": "Drahu\u0161e Wro\u017eynov\u00e1",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Kristina Star\u00e1",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Emil Holub",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Romana Brou\u0161kov\u00e1",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Tom\u00e1\u0161 \u0160t\u011bp\u00e1n",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Karel Havl\u00ed\u010dek",
                "time": "18:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-04-24",
        "reservations": [
            {
                "name": "Adam Smol\u00edk",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Nikola B\u00ednov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Radka Pastrnkov\u00e1",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Drahu\u0161e Wro\u017eynov\u00e1",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Jaroslava Pasekov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-04-25",
        "reservations": [
            {
                "name": "Daniel Vrba",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Renata Hrochov\u00e1",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Martin V\u0161et\u00fd\u010dek",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Kristina Star\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-04-26",
        "reservations": [
            {
                "name": "Michaela Krej\u010dov\u00e1",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Zd\u011bnka Langerov\u00e1",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "V\u00e1clava Rul\u00edkov\u00e1",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ludmila Ruck\u00e1",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Hynek Ve\u010de\u0159a",
                "time": "15:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-04-27",
        "reservations": [
            {
                "name": "Natalia \u0160ruba\u0159ov\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Robert Maier",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ludmila Ruck\u00e1",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Dimitrios Ku\u010dera",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Martina B\u011bhunkov\u00e1",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Stanislav \u010c\u00ed\u017eek",
                "time": "08:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-04-28",
        "reservations": [
            {
                "name": "Jind\u0159ich Bud\u00edn",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jarmila St\u00fctzov\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-04-29",
        "reservations": [
            {
                "name": "Adam Smol\u00edk",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Milan B\u00edlek",
                "time": "11:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-04-30",
        "reservations": [
            {
                "name": "Jaroslav Milda",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Vlastimil Hl\u00e1vka",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Karel Havl\u00ed\u010dek",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Kristina Star\u00e1",
                "time": "13:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-05-01",
        "reservations": [
            {
                "name": "Ani Mileva K\u0159emenov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Roman Polinsk\u00fd",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ji\u0159\u00ed \u010cadil",
                "time": "09:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-05-02",
        "reservations": [
            {
                "name": "Martina B\u011bhunkov\u00e1",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "Vladim\u00edr Fatka",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jaroslav Milda",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Quoc Hung V\u00e1vra",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Jitka Pruckov\u00e1",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ji\u0159ina Drmolov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-05-03",
        "reservations": [
            {
                "name": "Juraj Michn\u00edk",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "\u0160t\u011bp\u00e1nka Profantov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Irena Votavov\u00e1",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Anton\u00edn Kutil",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Quoc Hung V\u00e1vra",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Lenka \u0160vecov\u00e1",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Pavel Such\u00fd",
                "time": "16:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-05-04",
        "reservations": [
            {
                "name": "Dana \u010celedov\u00e1",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Dimitrios Ku\u010dera",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Alan Kohout",
                "time": "17:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-05-05",
        "reservations": [
            {
                "name": "Drahu\u0161e Wro\u017eynov\u00e1",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Helena Kohoutov\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Petra Vorlov\u00e1",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Alena Leitnerov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Tom\u00e1\u0161 \u0160t\u011bp\u00e1n",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Martina B\u011bhunkov\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-05-06",
        "reservations": [
            {
                "name": "Daniel Vrba",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Miroslav \u0160udoma",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Vladislav Do\u010dkal",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Renata Hrochov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Helena Kohoutov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-05-07",
        "reservations": [
            {
                "name": "Martin V\u0161et\u00fd\u010dek",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ji\u0159ina Drmolov\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Milo\u0161 Vit\u00e1sek",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Hana \u0160ikulov\u00e1",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Adam Smol\u00edk",
                "time": "14:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-05-08",
        "reservations": [
            {
                "name": "Mat\u011bj Suda",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Vladim\u00edr Fatka",
                "time": "13:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-05-09",
        "reservations": [
            {
                "name": "Vladislav Do\u010dkal",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Radim Hor\u00e1k",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Juraj Michn\u00edk",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jind\u0159ich Bud\u00edn",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Karel Havl\u00ed\u010dek",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Hana \u0160ikulov\u00e1",
                "time": "08:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-05-10",
        "reservations": [
            {
                "name": "Vojt\u011bch Brul\u00edk",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Jaroslava Pasekov\u00e1",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ladislav Tr\u00e1vn\u00ed\u010dek",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Marie Samcov\u00e1",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Milan B\u00edlek",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Vojt\u011bch Brul\u00edk",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Milo\u0161 Vit\u00e1sek",
                "time": "15:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-05-11",
        "reservations": [
            {
                "name": "Martina Jun",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Milo\u0161 Vit\u00e1sek",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Vojt\u011bch Brul\u00edk",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Jana Kratochv\u00edlov\u00e1",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Mat\u011bj Suda",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Zden\u011bk Kobliha",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Zden\u011bk Kobliha",
                "time": "09:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-05-12",
        "reservations": [
            {
                "name": "Zd\u011bnka Langerov\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Milena Ridajov\u00e1",
                "time": "11:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-05-13",
        "reservations": [
            {
                "name": "\u0160t\u011bp\u00e1nka Profantov\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Helena Kohoutov\u00e1",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Metod\u011bj Selinger",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Vlastimil Hl\u00e1vka",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Natalia \u0160ruba\u0159ov\u00e1",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Drahu\u0161e Wro\u017eynov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ludmila Ruck\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-05-14",
        "reservations": [
            {
                "name": "Stefania Davisov\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "David Rou\u0161ar",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Alan Kohout",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jaroslava Pasekov\u00e1",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Libu\u0161e Poulov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Stefania Davisov\u00e1",
                "time": "14:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-05-15",
        "reservations": [
            {
                "name": "Ani Mileva K\u0159emenov\u00e1",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Jitka Pruckov\u00e1",
                "time": "13:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-05-16",
        "reservations": [
            {
                "name": "Martin V\u0161et\u00fd\u010dek",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ladislav Tr\u00e1vn\u00ed\u010dek",
                "time": "11:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-05-17",
        "reservations": [
            {
                "name": "Stanislav \u010c\u00ed\u017eek",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Mat\u011bj Suda",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Marek \u0160koln\u00edk",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Lubom\u00edr Kl\u00edma",
                "time": "08:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-05-18",
        "reservations": [
            {
                "name": "Marie Samcov\u00e1",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Colin Pla\u010dek",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ren\u00e1ta Foksov\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Stanislav \u010c\u00ed\u017eek",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Milena Ridajov\u00e1",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ren\u00e1ta Foksov\u00e1",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ren\u00e1ta Foksov\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-05-19",
        "reservations": [
            {
                "name": "\u0160t\u011bp\u00e1nka Profantov\u00e1",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ji\u0159ina Drmolov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ludmila Ruck\u00e1",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Stanislav \u010c\u00ed\u017eek",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "Roman Polinsk\u00fd",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Lucie Kop\u0159ivov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Iveta Nowakov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-05-20",
        "reservations": [
            {
                "name": "Ladislav Tr\u00e1vn\u00ed\u010dek",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "V\u011bra Makov\u00e1",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Vlasta Hrabov\u00e1",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jaroslava Pasekov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Milo\u0161 Vit\u00e1sek",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Hynek Ve\u010de\u0159a",
                "time": "12:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-05-21",
        "reservations": [
            {
                "name": "Milena Ridajov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Juraj Michn\u00edk",
                "time": "14:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-05-22",
        "reservations": [
            {
                "name": "Nade\u017eda Barto\u0148ov\u00e1",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Juraj Michn\u00edk",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Nikola B\u00ednov\u00e1",
                "time": "18:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-05-23",
        "reservations": [
            {
                "name": "Lubom\u00edr Kl\u00edma",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Helena Kohoutov\u00e1",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Dagmar \u010cajkov\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-05-24",
        "reservations": [
            {
                "name": "Natalia \u0160ruba\u0159ov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Alan Kohout",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Kamila Vejsadov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Renata Hrochov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-05-25",
        "reservations": [
            {
                "name": "Jaroslav Milda",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Alena Leitnerov\u00e1",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "Bo\u017eena Herltov\u00e1",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Hana \u0160ikulov\u00e1",
                "time": "16:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-05-26",
        "reservations": [
            {
                "name": "Krist\u00fdna Bajgarov\u00e1",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Pavel Such\u00fd",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Nad\u011b\u017eda Chalupov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Barbora Z\u00e1vadov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Kate\u0159ina \u0160im\u00e1kov\u00e1",
                "time": "18:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-05-27",
        "reservations": [
            {
                "name": "Jaroslav Milda",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Roman Polinsk\u00fd",
                "time": "18:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-05-28",
        "reservations": [
            {
                "name": "Alexandr Bruzl",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Josef Lutsak",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Dana \u010celedov\u00e1",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Anna Noskov\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Drahu\u0161e Wro\u017eynov\u00e1",
                "time": "10:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-05-29",
        "reservations": [
            {
                "name": "Alexandr Bruzl",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Karel Havl\u00ed\u010dek",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "V\u011bra Makov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Michal Faiereisl",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Martina B\u011bhunkov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-05-30",
        "reservations": [
            {
                "name": "Hana \u0160ikulov\u00e1",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Nad\u011b\u017eda Chalupov\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "V\u00e1clav Graja",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Zd\u011bnka Langerov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Krist\u00fdna Bajgarov\u00e1",
                "time": "13:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-05-31",
        "reservations": [
            {
                "name": "Michal Faiereisl",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Vladim\u00edr Fatka",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ludmila Ruck\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Libu\u0161e Poulov\u00e1",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jakub Bla\u017eek",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ji\u0159ina Drmolov\u00e1",
                "time": "09:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-06-01",
        "reservations": [
            {
                "name": "Ani Mileva K\u0159emenov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Dagmar \u010cajkov\u00e1",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Juraj Michn\u00edk",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Sergiy Koudela",
                "time": "16:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-06-02",
        "reservations": [
            {
                "name": "Krist\u00fdna Bajgarov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Nikola B\u00ednov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Miroslav \u0160udoma",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "V\u00e1clav Graja",
                "time": "17:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-06-03",
        "reservations": [
            {
                "name": "Jakub Bla\u017eek",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Zd\u011bnka Langerov\u00e1",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Vladislav Do\u010dkal",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Josef Lutsak",
                "time": "12:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-06-04",
        "reservations": [
            {
                "name": "Ladislav Tr\u00e1vn\u00ed\u010dek",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Dagmar \u010cajkov\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Martin V\u0161et\u00fd\u010dek",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Adam Smol\u00edk",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Nad\u011b\u017eda Chalupov\u00e1",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Vlastimil Hl\u00e1vka",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Stanislav \u010c\u00ed\u017eek",
                "time": "16:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-06-05",
        "reservations": [
            {
                "name": "Alan Kohout",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Kamila Vejsadov\u00e1",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Libor Joh\u00e1nek",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ond\u0159ej Robl",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Jakub Bla\u017eek",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ludmila Ruck\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-06-06",
        "reservations": [
            {
                "name": "Lucie Kop\u0159ivov\u00e1",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Juraj Michn\u00edk",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "Vladislav Do\u010dkal",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Petr Br\u016f\u017eek",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Irena Votavov\u00e1",
                "time": "10:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-06-07",
        "reservations": [
            {
                "name": "Zden\u011bk Kobliha",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Juraj Michn\u00edk",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Adam Smol\u00edk",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Zden\u011bk Kobliha",
                "time": "11:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-06-08",
        "reservations": [
            {
                "name": "Sergiy Koudela",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "V\u011bra Makov\u00e1",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jaroslava Pasekov\u00e1",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Renata Hrochov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-06-09",
        "reservations": [
            {
                "name": "Jana Kratochv\u00edlov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Hynek Ve\u010de\u0159a",
                "time": "09:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-06-10",
        "reservations": [
            {
                "name": "Hana \u0160ikulov\u00e1",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Anna Noskov\u00e1",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Dagmar \u010cajkov\u00e1",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Nad\u011b\u017eda Chalupov\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Emil Holub",
                "time": "13:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-06-11",
        "reservations": [
            {
                "name": "Pavel Such\u00fd",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jarmila St\u00fctzov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Eva Va\u0148ov\u00e1",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Marie Samcov\u00e1",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Adam Smol\u00edk",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Luk\u00e1\u0161 Trnka",
                "time": "13:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-06-12",
        "reservations": [
            {
                "name": "Irena Votavov\u00e1",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Tereza Kvasni\u010dkov\u00e1",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Zden\u011bk Kobliha",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Martin V\u0161et\u00fd\u010dek",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Stefania Davisov\u00e1",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Quoc Hung V\u00e1vra",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Kate\u0159ina \u0160im\u00e1kov\u00e1",
                "time": "16:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-06-13",
        "reservations": [
            {
                "name": "Libor Joh\u00e1nek",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Eva Va\u0148ov\u00e1",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Pavel Such\u00fd",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Zden\u011bk Kobliha",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "Juraj Michn\u00edk",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Nade\u017eda Barto\u0148ov\u00e1",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jan Skrbek",
                "time": "09:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-06-14",
        "reservations": [
            {
                "name": "Martina Jun",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Tom\u00e1\u0161 \u0160t\u011bp\u00e1n",
                "time": "13:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-06-15",
        "reservations": [
            {
                "name": "Vladislav Do\u010dkal",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Miroslav \u0160udoma",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Tom\u00e1\u0161 \u0160t\u011bp\u00e1n",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Veronika Tomkov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-06-16",
        "reservations": [
            {
                "name": "Jitka Pruckov\u00e1",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Josef Lutsak",
                "time": "15:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-06-17",
        "reservations": [
            {
                "name": "Emil Holub",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Vlasta Hrabov\u00e1",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Milo\u0161 Vit\u00e1sek",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Tom\u00e1\u0161 \u0160t\u011bp\u00e1n",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Zd\u011bnka Langerov\u00e1",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Krist\u00fdna Bajgarov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Drahom\u00edra Pa\u017adziorov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-06-18",
        "reservations": [
            {
                "name": "Jitka Pruckov\u00e1",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Nikola B\u00ednov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-06-19",
        "reservations": [
            {
                "name": "Zuzana Ku\u010derov\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Zden\u011bk Kobliha",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Stefania Davisov\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-06-20",
        "reservations": [
            {
                "name": "Eva Va\u0148ov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Helena Kohoutov\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Kamila Vejsadov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Eva Va\u0148ov\u00e1",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Drahu\u0161e Wro\u017eynov\u00e1",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Dimitrios Ku\u010dera",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Mat\u011bj Suda",
                "time": "08:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-06-21",
        "reservations": [
            {
                "name": "Hynek Ve\u010de\u0159a",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Barbara Souhradov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Robert Maier",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ivanka B\u00edlkov\u00e1",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jaroslava Pasekov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-06-22",
        "reservations": [
            {
                "name": "Drahom\u00edra Pa\u017adziorov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Colin Pla\u010dek",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Martina B\u011bhunkov\u00e1",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Hynek Ve\u010de\u0159a",
                "time": "15:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-06-23",
        "reservations": [
            {
                "name": "Adam Smol\u00edk",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Barbara Souhradov\u00e1",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Romana Brou\u0161kov\u00e1",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Jaroslava Pasekov\u00e1",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Milan B\u00edlek",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Libor Joh\u00e1nek",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Alexandr Bruzl",
                "time": "16:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-06-24",
        "reservations": [
            {
                "name": "Sergiy Koudela",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Franti\u0161ek Jel\u00ednek",
                "time": "11:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-06-25",
        "reservations": [
            {
                "name": "Bo\u017eena Herltov\u00e1",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ji\u0159ina Drmolov\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Dana \u010celedov\u00e1",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Radka Pastrnkov\u00e1",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Metod\u011bj Selinger",
                "time": "15:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-06-26",
        "reservations": [
            {
                "name": "Petra Vorlov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ren\u00e1ta Foksov\u00e1",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Alena Leitnerov\u00e1",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Petra Vorlov\u00e1",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Daniel Vrba",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Vladislav Do\u010dkal",
                "time": "10:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-06-27",
        "reservations": [
            {
                "name": "Krist\u00fdna Bajgarov\u00e1",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Josef Lutsak",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Martina Jun",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ji\u0159\u00ed \u010cadil",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "V\u00e1clav Graja",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Tom\u00e1\u0161 \u0160t\u011bp\u00e1n",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Radim Hor\u00e1k",
                "time": "18:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-06-28",
        "reservations": [
            {
                "name": "Tereza Kvasni\u010dkov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Pavel Such\u00fd",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jakub Bla\u017eek",
                "time": "14:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-06-29",
        "reservations": [
            {
                "name": "Jind\u0159ich Bud\u00edn",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Juraj Michn\u00edk",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ludmila Ruck\u00e1",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Zuzana Ku\u010derov\u00e1",
                "time": "14:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-06-30",
        "reservations": [
            {
                "name": "Libu\u0161e Poulov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Vlasta Hrabov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Milo\u0161 Vit\u00e1sek",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ani Mileva K\u0159emenov\u00e1",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Tereza Kvasni\u010dkov\u00e1",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ivana Ka\u0161parov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-07-01",
        "reservations": [
            {
                "name": "Martina Jun",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Roman Polinsk\u00fd",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Tereza Kvasni\u010dkov\u00e1",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Lucie Kop\u0159ivov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Alan Kohout",
                "time": "17:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-07-02",
        "reservations": [
            {
                "name": "Emil Holub",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Martin V\u0161et\u00fd\u010dek",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Nade\u017eda Barto\u0148ov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Helena Kohoutov\u00e1",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Lucie Kop\u0159ivov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Zd\u011bnka Langerov\u00e1",
                "time": "11:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-07-03",
        "reservations": [
            {
                "name": "Dimitrios Ku\u010dera",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Dana \u010celedov\u00e1",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Adam Smol\u00edk",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Adam Smol\u00edk",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Kamila Vejsadov\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-07-04",
        "reservations": [
            {
                "name": "Romana Brou\u0161kov\u00e1",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Daniel Vrba",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jaroslava Pasekov\u00e1",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Radka Pastrnkov\u00e1",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "Lucie Kop\u0159ivov\u00e1",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Bo\u017eena Herltov\u00e1",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Libor Joh\u00e1nek",
                "time": "12:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-07-05",
        "reservations": [
            {
                "name": "Hynek Ve\u010de\u0159a",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Kate\u0159ina \u0160im\u00e1kov\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-07-06",
        "reservations": [
            {
                "name": "Tereza Kvasni\u010dkov\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Kristina Star\u00e1",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ren\u00e1ta Foksov\u00e1",
                "time": "18:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-07-07",
        "reservations": [
            {
                "name": "Natalia \u0160ruba\u0159ov\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Veronika Tomkov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Renata Hrochov\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Vlasta Hrabov\u00e1",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Luk\u00e1\u0161 Trnka",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ond\u0159ej Robl",
                "time": "12:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-07-08",
        "reservations": [
            {
                "name": "Radim Hor\u00e1k",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Dimitrios Ku\u010dera",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Mat\u011bj Suda",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Vojt\u011bch Brul\u00edk",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "David Rou\u0161ar",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Anton\u00edn Kutil",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jarmila St\u00fctzov\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-07-09",
        "reservations": [
            {
                "name": "Milo\u0161 Vit\u00e1sek",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Nikola B\u00ednov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Lubom\u00edr Kl\u00edma",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Milena Ridajov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-07-10",
        "reservations": [
            {
                "name": "Hynek Ve\u010de\u0159a",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Franti\u0161ek Jel\u00ednek",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Luk\u00e1\u0161 Trnka",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Nad\u011b\u017eda Chalupov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Barbara Souhradov\u00e1",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Karel Havl\u00ed\u010dek",
                "time": "15:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-07-11",
        "reservations": [
            {
                "name": "Juraj Michn\u00edk",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ivana Ka\u0161parov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Quoc Hung V\u00e1vra",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Drahu\u0161e Wro\u017eynov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-07-12",
        "reservations": [
            {
                "name": "Jaroslav Milda",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Milo\u0161 Vit\u00e1sek",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Kate\u0159ina \u0160im\u00e1kov\u00e1",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Helena Kohoutov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-07-13",
        "reservations": [
            {
                "name": "Barbara Souhradov\u00e1",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Dimitrios Ku\u010dera",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Bo\u017eena Herltov\u00e1",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Adam Smol\u00edk",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Krist\u00fdna Bajgarov\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-07-14",
        "reservations": [
            {
                "name": "\u0160t\u011bp\u00e1nka Profantov\u00e1",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Anna Noskov\u00e1",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Kate\u0159ina \u0160im\u00e1kov\u00e1",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ji\u0159\u00ed \u010cadil",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Helena Kohoutov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ivana Ka\u0161parov\u00e1",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Josef Lutsak",
                "time": "10:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-07-15",
        "reservations": [
            {
                "name": "V\u011bra Makov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Petra Vorlov\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Michal Faiereisl",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Adam Smol\u00edk",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ond\u0159ej Robl",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Adam Smol\u00edk",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Irena Votavov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-07-16",
        "reservations": [
            {
                "name": "Jan Skrbek",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Michaela Krej\u010dov\u00e1",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Vlasta Hrabov\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ji\u0159\u00ed \u010cadil",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Lucie Kop\u0159ivov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Alan Kohout",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Hana \u0160ikulov\u00e1",
                "time": "16:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-07-17",
        "reservations": [
            {
                "name": "Alan Kohout",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Josef Lutsak",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Josef Lutsak",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Martina B\u011bhunkov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Irena Votavov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-07-18",
        "reservations": [
            {
                "name": "Kate\u0159ina \u0160im\u00e1kov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Lucie Kop\u0159ivov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Milena Ridajov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Milena Ridajov\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Nade\u017eda Barto\u0148ov\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Marie Samcov\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-07-19",
        "reservations": [
            {
                "name": "Alexandr Bruzl",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ladislav Tr\u00e1vn\u00ed\u010dek",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Kate\u0159ina \u0160im\u00e1kov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-07-20",
        "reservations": [
            {
                "name": "Robert Maier",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Martina Jun",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Petra Vorlov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ani Mileva K\u0159emenov\u00e1",
                "time": "13:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-07-21",
        "reservations": [
            {
                "name": "Jana Kratochv\u00edlov\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ji\u0159\u00ed \u010cadil",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Zuzana Ku\u010derov\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Barbora Z\u00e1vadov\u00e1",
                "time": "18:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-07-22",
        "reservations": [
            {
                "name": "Jarmila St\u00fctzov\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Emil Holub",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Vlastimil Hl\u00e1vka",
                "time": "09:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-07-23",
        "reservations": [
            {
                "name": "Alan Kohout",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Renata Hrochov\u00e1",
                "time": "16:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-07-24",
        "reservations": [
            {
                "name": "Quoc Hung V\u00e1vra",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Nikola B\u00ednov\u00e1",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Metod\u011bj Selinger",
                "time": "12:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-07-25",
        "reservations": [
            {
                "name": "Ond\u0159ej Robl",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Alexandr Bruzl",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Vlasta Hrabov\u00e1",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Dagmar \u010cajkov\u00e1",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Barbora Z\u00e1vadov\u00e1",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ji\u0159ina Drmolov\u00e1",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Sergiy Koudela",
                "time": "11:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-07-26",
        "reservations": [
            {
                "name": "Milena Ridajov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Nade\u017eda Barto\u0148ov\u00e1",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ivanka B\u00edlkov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Lucie Kop\u0159ivov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-07-27",
        "reservations": [
            {
                "name": "Radim Hor\u00e1k",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Vlastimil Hl\u00e1vka",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Kamila Vejsadov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Radka Pastrnkov\u00e1",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "Vladislav Do\u010dkal",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Tom\u00e1\u0161 \u0160t\u011bp\u00e1n",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Veronika Tomkov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-07-28",
        "reservations": [
            {
                "name": "Vlastimil Hl\u00e1vka",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Tom\u00e1\u0161 \u0160t\u011bp\u00e1n",
                "time": "09:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-07-29",
        "reservations": [
            {
                "name": "Lucie Kop\u0159ivov\u00e1",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Franti\u0161ek Jel\u00ednek",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Lucie Kop\u0159ivov\u00e1",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Metod\u011bj Selinger",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jitka Pruckov\u00e1",
                "time": "13:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-07-30",
        "reservations": [
            {
                "name": "Tereza Kvasni\u010dkov\u00e1",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Miroslav \u0160udoma",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Hana \u0160ikulov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Luk\u00e1\u0161 Trnka",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Kristina Star\u00e1",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Marie Samcov\u00e1",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Petra Vorlov\u00e1",
                "time": "14:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-07-31",
        "reservations": [
            {
                "name": "Ani Mileva K\u0159emenov\u00e1",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Marek \u0160koln\u00edk",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Bo\u017eena Herltov\u00e1",
                "time": "11:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-08-01",
        "reservations": [
            {
                "name": "Ji\u0159ina Drmolov\u00e1",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Krist\u00fdna Bajgarov\u00e1",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Kate\u0159ina \u0160im\u00e1kov\u00e1",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Zd\u011bnka Langerov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-08-02",
        "reservations": [
            {
                "name": "Lenka \u0160vecov\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ludmila Ruck\u00e1",
                "time": "16:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-08-03",
        "reservations": [
            {
                "name": "Alexandr Bruzl",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ladislav Tr\u00e1vn\u00ed\u010dek",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Quoc Hung V\u00e1vra",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Drahom\u00edra Pa\u017adziorov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Drahu\u0161e Wro\u017eynov\u00e1",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Irena Votavov\u00e1",
                "time": "14:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-08-04",
        "reservations": [
            {
                "name": "Alena Leitnerov\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Irena Votavov\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Bo\u017eena Herltov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Helena Kohoutov\u00e1",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Nade\u017eda Barto\u0148ov\u00e1",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Vlasta Hrabov\u00e1",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Nikola B\u00ednov\u00e1",
                "time": "18:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-08-05",
        "reservations": [
            {
                "name": "Marek \u0160koln\u00edk",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Anna Noskov\u00e1",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jaroslava Pasekov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Emil Holub",
                "time": "12:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-08-06",
        "reservations": [
            {
                "name": "Ivanka B\u00edlkov\u00e1",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "V\u011bra Makov\u00e1",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "Irena Votavov\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Nikola B\u00ednov\u00e1",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Kate\u0159ina \u0160im\u00e1kov\u00e1",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Alena Leitnerov\u00e1",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Lucie Kop\u0159ivov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-08-07",
        "reservations": [
            {
                "name": "Vlastimil Hl\u00e1vka",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Martin V\u0161et\u00fd\u010dek",
                "time": "18:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-08-08",
        "reservations": [
            {
                "name": "Sergiy Koudela",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Drahu\u0161e Wro\u017eynov\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Milan B\u00edlek",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Dagmar \u010cajkov\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Luk\u00e1\u0161 Trnka",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Stanislav \u010c\u00ed\u017eek",
                "time": "10:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-08-09",
        "reservations": [
            {
                "name": "Radka Pastrnkov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ren\u00e1ta Foksov\u00e1",
                "time": "09:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-08-10",
        "reservations": [
            {
                "name": "V\u00e1clava Rul\u00edkov\u00e1",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Vojt\u011bch Brul\u00edk",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Pavel Such\u00fd",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Tereza Kvasni\u010dkov\u00e1",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ludmila Ruck\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Sergiy Koudela",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jaroslava Pasekov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-08-11",
        "reservations": [
            {
                "name": "Libu\u0161e Poulov\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Alena Leitnerov\u00e1",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Lubom\u00edr Kl\u00edma",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "Quoc Hung V\u00e1vra",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Nad\u011b\u017eda Chalupov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-08-12",
        "reservations": [
            {
                "name": "Dagmar \u010cajkov\u00e1",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Vlastimil Hl\u00e1vka",
                "time": "08:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-08-13",
        "reservations": [
            {
                "name": "Martin V\u0161et\u00fd\u010dek",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Robert Maier",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jind\u0159ich Bud\u00edn",
                "time": "14:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-08-14",
        "reservations": [
            {
                "name": "Nikola B\u00ednov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jan Skrbek",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Anna Noskov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Kristina Star\u00e1",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Martina Jun",
                "time": "10:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-08-15",
        "reservations": [
            {
                "name": "Stefania Davisov\u00e1",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Nad\u011b\u017eda Chalupov\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-08-16",
        "reservations": [
            {
                "name": "Tereza Kvasni\u010dkov\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Barbora Z\u00e1vadov\u00e1",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Stanislav \u010c\u00ed\u017eek",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Colin Pla\u010dek",
                "time": "12:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-08-17",
        "reservations": [
            {
                "name": "Radka Pastrnkov\u00e1",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Lucie Kop\u0159ivov\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Alan Kohout",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ond\u0159ej Robl",
                "time": "17:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-08-18",
        "reservations": [
            {
                "name": "Krist\u00fdna Bajgarov\u00e1",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Martin V\u0161et\u00fd\u010dek",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ji\u0159\u00ed \u010cadil",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Radim Hor\u00e1k",
                "time": "13:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-08-19",
        "reservations": [
            {
                "name": "Jaroslav Milda",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Renata Hrochov\u00e1",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Petr Br\u016f\u017eek",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Hana \u0160ikulov\u00e1",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Kate\u0159ina \u0160im\u00e1kov\u00e1",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Vladislav Do\u010dkal",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Martina B\u011bhunkov\u00e1",
                "time": "18:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-08-20",
        "reservations": [
            {
                "name": "Pavel Such\u00fd",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Zuzana Ku\u010derov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Pavel Such\u00fd",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Veronika Tomkov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jaroslav Milda",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Franti\u0161ek Jel\u00ednek",
                "time": "15:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-08-21",
        "reservations": [
            {
                "name": "Emil Holub",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Hana \u0160ikulov\u00e1",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jind\u0159ich Bud\u00edn",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Anna Noskov\u00e1",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Vlasta Hrabov\u00e1",
                "time": "08:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-08-22",
        "reservations": [
            {
                "name": "Alexandr Bruzl",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Barbora Z\u00e1vadov\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Dana \u010celedov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Robert Maier",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Marie Samcov\u00e1",
                "time": "14:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-08-23",
        "reservations": [
            {
                "name": "Krist\u00fdna Bajgarov\u00e1",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ladislav Tr\u00e1vn\u00ed\u010dek",
                "time": "17:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-08-24",
        "reservations": [
            {
                "name": "Ivanka B\u00edlkov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "David Rou\u0161ar",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Robert Maier",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Mat\u011bj Suda",
                "time": "09:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-08-25",
        "reservations": [
            {
                "name": "Iveta Nowakov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ivana Ka\u0161parov\u00e1",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Juraj Michn\u00edk",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Drahu\u0161e Wro\u017eynov\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-08-26",
        "reservations": [
            {
                "name": "Vladislav Do\u010dkal",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Libu\u0161e Poulov\u00e1",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Hynek Ve\u010de\u0159a",
                "time": "15:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-08-27",
        "reservations": [
            {
                "name": "Daniel Vrba",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Jan Skrbek",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Krist\u00fdna Bajgarov\u00e1",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Michal Faiereisl",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Irena Votavov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Sergiy Koudela",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Colin Pla\u010dek",
                "time": "08:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-08-28",
        "reservations": [
            {
                "name": "Eva Va\u0148ov\u00e1",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Radim Hor\u00e1k",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Radim Hor\u00e1k",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Martina B\u011bhunkov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-08-29",
        "reservations": [
            {
                "name": "Vladislav Do\u010dkal",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Radim Hor\u00e1k",
                "time": "14:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-08-30",
        "reservations": [
            {
                "name": "Kamila Vejsadov\u00e1",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Petra Vorlov\u00e1",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "V\u011bra Makov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-08-31",
        "reservations": [
            {
                "name": "Kate\u0159ina \u0160im\u00e1kov\u00e1",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Kristina Star\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Radka Pastrnkov\u00e1",
                "time": "18:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-09-01",
        "reservations": [
            {
                "name": "Irena Votavov\u00e1",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Zd\u011bnka Langerov\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Zuzana Ku\u010derov\u00e1",
                "time": "11:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-09-02",
        "reservations": [
            {
                "name": "Michaela Krej\u010dov\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Marie Samcov\u00e1",
                "time": "14:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-09-03",
        "reservations": [
            {
                "name": "Zd\u011bnka Langerov\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Daniel Vrba",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ren\u00e1ta Foksov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Marek \u0160koln\u00edk",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Irena Votavov\u00e1",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Josef Lutsak",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Irena Votavov\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-09-04",
        "reservations": [
            {
                "name": "Ani Mileva K\u0159emenov\u00e1",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Veronika Tomkov\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-09-05",
        "reservations": [
            {
                "name": "Veronika Tomkov\u00e1",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Stefania Davisov\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Kristina Star\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-09-06",
        "reservations": [
            {
                "name": "Nade\u017eda Barto\u0148ov\u00e1",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ond\u0159ej Robl",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Josef Lutsak",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Radim Hor\u00e1k",
                "time": "13:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-09-07",
        "reservations": [
            {
                "name": "Nad\u011b\u017eda Chalupov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ani Mileva K\u0159emenov\u00e1",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Vlastimil Hl\u00e1vka",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jitka Pruckov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Lenka \u0160vecov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-09-08",
        "reservations": [
            {
                "name": "Helena Kohoutov\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "V\u00e1clava Rul\u00edkov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Colin Pla\u010dek",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ji\u0159ina Drmolov\u00e1",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Marie Samcov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Pavel Such\u00fd",
                "time": "14:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-09-09",
        "reservations": [
            {
                "name": "Jind\u0159ich Bud\u00edn",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Bo\u017eena Herltov\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-09-10",
        "reservations": [
            {
                "name": "Ivanka B\u00edlkov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "V\u00e1clava Rul\u00edkov\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Radka Pastrnkov\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Dana \u010celedov\u00e1",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Vladislav Do\u010dkal",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ludmila Ruck\u00e1",
                "time": "09:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-09-11",
        "reservations": [
            {
                "name": "Jind\u0159ich Bud\u00edn",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Mat\u011bj Suda",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Martin V\u0161et\u00fd\u010dek",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Luk\u00e1\u0161 Trnka",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Nad\u011b\u017eda Chalupov\u00e1",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Vojt\u011bch Brul\u00edk",
                "time": "18:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-09-12",
        "reservations": [
            {
                "name": "Metod\u011bj Selinger",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Marie Samcov\u00e1",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Libu\u0161e Poulov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Barbara Souhradov\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Franti\u0161ek Jel\u00ednek",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Dagmar \u010cajkov\u00e1",
                "time": "15:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-09-13",
        "reservations": [
            {
                "name": "Lenka \u0160vecov\u00e1",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Stefania Davisov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jaroslava Pasekov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Luk\u00e1\u0161 Trnka",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Alena Leitnerov\u00e1",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Kate\u0159ina \u0160im\u00e1kov\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-09-14",
        "reservations": [
            {
                "name": "Vladim\u00edr Fatka",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Vladislav Do\u010dkal",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Marek \u0160koln\u00edk",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Jaroslav Milda",
                "time": "10:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-09-15",
        "reservations": [
            {
                "name": "Jakub Bla\u017eek",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Dagmar \u010cajkov\u00e1",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Quoc Hung V\u00e1vra",
                "time": "11:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-09-16",
        "reservations": [
            {
                "name": "Eva Va\u0148ov\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Michaela Krej\u010dov\u00e1",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ren\u00e1ta Foksov\u00e1",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Metod\u011bj Selinger",
                "time": "14:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-09-17",
        "reservations": [
            {
                "name": "Sergiy Koudela",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Marie Samcov\u00e1",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Vladislav Do\u010dkal",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Iveta Nowakov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Pavel Such\u00fd",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Michal Faiereisl",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Milan B\u00edlek",
                "time": "17:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-09-18",
        "reservations": [
            {
                "name": "Luk\u00e1\u0161 Trnka",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ladislav Tr\u00e1vn\u00ed\u010dek",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ladislav Tr\u00e1vn\u00ed\u010dek",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "V\u011bra Makov\u00e1",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Juraj Michn\u00edk",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Milan B\u00edlek",
                "time": "12:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-09-19",
        "reservations": [
            {
                "name": "Milo\u0161 Vit\u00e1sek",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "David Rou\u0161ar",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Nad\u011b\u017eda Chalupov\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Bo\u017eena Herltov\u00e1",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Milena Ridajov\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Petr Br\u016f\u017eek",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Vladislav Do\u010dkal",
                "time": "13:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-09-20",
        "reservations": [
            {
                "name": "Colin Pla\u010dek",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Anton\u00edn Kutil",
                "time": "08:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-09-21",
        "reservations": [
            {
                "name": "Jaroslav Milda",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Alexandr Bruzl",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Martina B\u011bhunkov\u00e1",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Martina Jun",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Petra Vorlov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jana Kratochv\u00edlov\u00e1",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Kamila Vejsadov\u00e1",
                "time": "13:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-09-22",
        "reservations": [
            {
                "name": "Jan Skrbek",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Jarmila St\u00fctzov\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Milo\u0161 Vit\u00e1sek",
                "time": "17:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-09-23",
        "reservations": [
            {
                "name": "Zd\u011bnka Langerov\u00e1",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Eva Va\u0148ov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-09-24",
        "reservations": [
            {
                "name": "Kamila Vejsadov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Kamila Vejsadov\u00e1",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Colin Pla\u010dek",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Franti\u0161ek Jel\u00ednek",
                "time": "09:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-09-25",
        "reservations": [
            {
                "name": "Dimitrios Ku\u010dera",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Dimitrios Ku\u010dera",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Martina Jun",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ji\u0159\u00ed \u010cadil",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Metod\u011bj Selinger",
                "time": "08:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-09-26",
        "reservations": [
            {
                "name": "Ji\u0159\u00ed \u010cadil",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ludmila Ruck\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Dagmar \u010cajkov\u00e1",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Kristina Star\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-09-27",
        "reservations": [
            {
                "name": "Zden\u011bk Kobliha",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ladislav Tr\u00e1vn\u00ed\u010dek",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Alan Kohout",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ond\u0159ej Robl",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ren\u00e1ta Foksov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Vladim\u00edr Fatka",
                "time": "18:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-09-28",
        "reservations": [
            {
                "name": "Libor Joh\u00e1nek",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Krist\u00fdna Bajgarov\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Nad\u011b\u017eda Chalupov\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Miroslav \u0160udoma",
                "time": "10:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-09-29",
        "reservations": [
            {
                "name": "Ren\u00e1ta Foksov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Kamila Vejsadov\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Franti\u0161ek Jel\u00ednek",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Libor Joh\u00e1nek",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Iveta Nowakov\u00e1",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Jana Kratochv\u00edlov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-09-30",
        "reservations": [
            {
                "name": "Karel Havl\u00ed\u010dek",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Daniel Vrba",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ond\u0159ej Robl",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Hana \u0160ikulov\u00e1",
                "time": "16:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-10-01",
        "reservations": [
            {
                "name": "Jakub Bla\u017eek",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Barbara Souhradov\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-10-02",
        "reservations": [
            {
                "name": "Michaela Krej\u010dov\u00e1",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ivana Ka\u0161parov\u00e1",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Kristina Star\u00e1",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Krist\u00fdna Bajgarov\u00e1",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Franti\u0161ek Jel\u00ednek",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Romana Brou\u0161kov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Bo\u017eena Herltov\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-10-03",
        "reservations": [
            {
                "name": "Hana \u0160ikulov\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Nade\u017eda Barto\u0148ov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jaroslav Milda",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Martina B\u011bhunkov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Jarmila St\u00fctzov\u00e1",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Vladislav Do\u010dkal",
                "time": "11:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-10-04",
        "reservations": [
            {
                "name": "Jakub Bla\u017eek",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "V\u00e1clav Graja",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Lubom\u00edr Kl\u00edma",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jitka Pruckov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Metod\u011bj Selinger",
                "time": "14:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-10-05",
        "reservations": [
            {
                "name": "Barbara Souhradov\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Martina Jun",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Dana \u010celedov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ond\u0159ej Robl",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Vojt\u011bch Brul\u00edk",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Michaela Krej\u010dov\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-10-06",
        "reservations": [
            {
                "name": "Drahu\u0161e Wro\u017eynov\u00e1",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Radim Hor\u00e1k",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jitka Pruckov\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Alan Kohout",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Barbora Z\u00e1vadov\u00e1",
                "time": "09:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-10-07",
        "reservations": [
            {
                "name": "Kamila Vejsadov\u00e1",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Vlasta Hrabov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ludmila Ruck\u00e1",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ani Mileva K\u0159emenov\u00e1",
                "time": "13:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-10-08",
        "reservations": [
            {
                "name": "Vlasta Hrabov\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Luk\u00e1\u0161 Trnka",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Drahom\u00edra Pa\u017adziorov\u00e1",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Nade\u017eda Barto\u0148ov\u00e1",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Martin V\u0161et\u00fd\u010dek",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "V\u00e1clava Rul\u00edkov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Miroslav \u0160udoma",
                "time": "15:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-10-09",
        "reservations": [
            {
                "name": "Sergiy Koudela",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Barbara Souhradov\u00e1",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jarmila St\u00fctzov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-10-10",
        "reservations": [
            {
                "name": "Robert Maier",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Dana \u010celedov\u00e1",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Anna Noskov\u00e1",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Quoc Hung V\u00e1vra",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ji\u0159\u00ed \u010cadil",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Quoc Hung V\u00e1vra",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "\u0160t\u011bp\u00e1nka Profantov\u00e1",
                "time": "14:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-10-11",
        "reservations": [
            {
                "name": "Dagmar \u010cajkov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Hana \u0160ikulov\u00e1",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Kristina Star\u00e1",
                "time": "14:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-10-12",
        "reservations": [
            {
                "name": "Franti\u0161ek Jel\u00ednek",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ludmila Ruck\u00e1",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jakub Bla\u017eek",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ren\u00e1ta Foksov\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Alan Kohout",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Alena Leitnerov\u00e1",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Robert Maier",
                "time": "17:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-10-13",
        "reservations": [
            {
                "name": "Radka Pastrnkov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jakub Bla\u017eek",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Kate\u0159ina \u0160im\u00e1kov\u00e1",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Daniel Vrba",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Vladislav Do\u010dkal",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Jakub Bla\u017eek",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Kristina Star\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-10-14",
        "reservations": [
            {
                "name": "Dimitrios Ku\u010dera",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Hynek Ve\u010de\u0159a",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jind\u0159ich Bud\u00edn",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Jaroslava Pasekov\u00e1",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jaroslava Pasekov\u00e1",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Daniel Vrba",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Libor Joh\u00e1nek",
                "time": "09:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-10-15",
        "reservations": [
            {
                "name": "Vlastimil Hl\u00e1vka",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Tereza Kvasni\u010dkov\u00e1",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Michaela Krej\u010dov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jind\u0159ich Bud\u00edn",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Marie Samcov\u00e1",
                "time": "16:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-10-16",
        "reservations": [
            {
                "name": "Ivana Ka\u0161parov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Stefania Davisov\u00e1",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Metod\u011bj Selinger",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Renata Hrochov\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-10-17",
        "reservations": [
            {
                "name": "V\u00e1clav Graja",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Vojt\u011bch Brul\u00edk",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Quoc Hung V\u00e1vra",
                "time": "16:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-10-18",
        "reservations": [
            {
                "name": "Jana Kratochv\u00edlov\u00e1",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Eva Va\u0148ov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Zuzana Ku\u010derov\u00e1",
                "time": "09:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-10-19",
        "reservations": [
            {
                "name": "Ren\u00e1ta Foksov\u00e1",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Helena Kohoutov\u00e1",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Barbora Z\u00e1vadov\u00e1",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Vladim\u00edr Fatka",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ji\u0159\u00ed \u010cadil",
                "time": "10:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-10-20",
        "reservations": [
            {
                "name": "Alan Kohout",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Hynek Ve\u010de\u0159a",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Hynek Ve\u010de\u0159a",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Radim Hor\u00e1k",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Alexandr Bruzl",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Luk\u00e1\u0161 Trnka",
                "time": "10:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-10-21",
        "reservations": [
            {
                "name": "Radim Hor\u00e1k",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Mat\u011bj Suda",
                "time": "18:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-10-22",
        "reservations": [
            {
                "name": "Jitka Pruckov\u00e1",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Martina B\u011bhunkov\u00e1",
                "time": "11:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-10-23",
        "reservations": [
            {
                "name": "Karel Havl\u00ed\u010dek",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Franti\u0161ek Jel\u00ednek",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Helena Kohoutov\u00e1",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Daniel Vrba",
                "time": "11:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-10-24",
        "reservations": [
            {
                "name": "Libu\u0161e Poulov\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Adam Smol\u00edk",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Barbora Z\u00e1vadov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jan Skrbek",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Dagmar \u010cajkov\u00e1",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Marie Samcov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-10-25",
        "reservations": [
            {
                "name": "Jan Skrbek",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jaroslav Milda",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Radka Pastrnkov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-10-26",
        "reservations": [
            {
                "name": "Kristina Star\u00e1",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Metod\u011bj Selinger",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ladislav Tr\u00e1vn\u00ed\u010dek",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Barbara Souhradov\u00e1",
                "time": "17:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-10-27",
        "reservations": [
            {
                "name": "V\u011bra Makov\u00e1",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Quoc Hung V\u00e1vra",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Dimitrios Ku\u010dera",
                "time": "11:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-10-28",
        "reservations": [
            {
                "name": "Ond\u0159ej Robl",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Bo\u017eena Herltov\u00e1",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Emil Holub",
                "time": "13:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-10-29",
        "reservations": [
            {
                "name": "Libor Joh\u00e1nek",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ani Mileva K\u0159emenov\u00e1",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Jana Kratochv\u00edlov\u00e1",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Karel Havl\u00ed\u010dek",
                "time": "15:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-10-30",
        "reservations": [
            {
                "name": "Bo\u017eena Herltov\u00e1",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Nade\u017eda Barto\u0148ov\u00e1",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Kristina Star\u00e1",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "\u0160t\u011bp\u00e1nka Profantov\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ani Mileva K\u0159emenov\u00e1",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Lucie Kop\u0159ivov\u00e1",
                "time": "18:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-10-31",
        "reservations": [
            {
                "name": "Nikola B\u00ednov\u00e1",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Drahom\u00edra Pa\u017adziorov\u00e1",
                "time": "18:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-11-01",
        "reservations": [
            {
                "name": "Karel Havl\u00ed\u010dek",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Dimitrios Ku\u010dera",
                "time": "18:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-11-02",
        "reservations": [
            {
                "name": "Alexandr Bruzl",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Adam Smol\u00edk",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Franti\u0161ek Jel\u00ednek",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ludmila Ruck\u00e1",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Hana \u0160ikulov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-11-03",
        "reservations": [
            {
                "name": "Hana \u0160ikulov\u00e1",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jarmila St\u00fctzov\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jitka Pruckov\u00e1",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Milo\u0161 Vit\u00e1sek",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Milan B\u00edlek",
                "time": "12:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-11-04",
        "reservations": [
            {
                "name": "Tom\u00e1\u0161 \u0160t\u011bp\u00e1n",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Marek \u0160koln\u00edk",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ren\u00e1ta Foksov\u00e1",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Karel Havl\u00ed\u010dek",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Dana \u010celedov\u00e1",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ivanka B\u00edlkov\u00e1",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Zd\u011bnka Langerov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-11-05",
        "reservations": [
            {
                "name": "Kate\u0159ina \u0160im\u00e1kov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Alan Kohout",
                "time": "16:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-11-06",
        "reservations": [
            {
                "name": "Drahu\u0161e Wro\u017eynov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "David Rou\u0161ar",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Marek \u0160koln\u00edk",
                "time": "17:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-11-07",
        "reservations": [
            {
                "name": "Iveta Nowakov\u00e1",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Adam Smol\u00edk",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Michal Faiereisl",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Libu\u0161e Poulov\u00e1",
                "time": "17:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-11-08",
        "reservations": [
            {
                "name": "Stanislav \u010c\u00ed\u017eek",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Milo\u0161 Vit\u00e1sek",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "V\u011bra Makov\u00e1",
                "time": "13:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-11-09",
        "reservations": [
            {
                "name": "Martina B\u011bhunkov\u00e1",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Marie Samcov\u00e1",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Adam Smol\u00edk",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Vladislav Do\u010dkal",
                "time": "18:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-11-10",
        "reservations": [
            {
                "name": "Drahom\u00edra Pa\u017adziorov\u00e1",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Marek \u0160koln\u00edk",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Miroslav \u0160udoma",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Romana Brou\u0161kov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Martin V\u0161et\u00fd\u010dek",
                "time": "08:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-11-11",
        "reservations": [
            {
                "name": "Milan B\u00edlek",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Drahu\u0161e Wro\u017eynov\u00e1",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Nade\u017eda Barto\u0148ov\u00e1",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Colin Pla\u010dek",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Adam Smol\u00edk",
                "time": "15:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-11-12",
        "reservations": [
            {
                "name": "Jakub Bla\u017eek",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ani Mileva K\u0159emenov\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Libu\u0161e Poulov\u00e1",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Lucie Kop\u0159ivov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ond\u0159ej Robl",
                "time": "09:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-11-13",
        "reservations": [
            {
                "name": "Marek \u0160koln\u00edk",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Milena Ridajov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-11-14",
        "reservations": [
            {
                "name": "Iveta Nowakov\u00e1",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "David Rou\u0161ar",
                "time": "14:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-11-15",
        "reservations": [
            {
                "name": "Ond\u0159ej Robl",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Nad\u011b\u017eda Chalupov\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Milena Ridajov\u00e1",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Metod\u011bj Selinger",
                "time": "11:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-11-16",
        "reservations": [
            {
                "name": "David Rou\u0161ar",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Luk\u00e1\u0161 Trnka",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Irena Votavov\u00e1",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Lenka \u0160vecov\u00e1",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Colin Pla\u010dek",
                "time": "10:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-11-17",
        "reservations": [
            {
                "name": "Alena Leitnerov\u00e1",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Vlastimil Hl\u00e1vka",
                "time": "13:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-11-18",
        "reservations": [
            {
                "name": "Kate\u0159ina \u0160im\u00e1kov\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "David Rou\u0161ar",
                "time": "08:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-11-19",
        "reservations": [
            {
                "name": "Martina B\u011bhunkov\u00e1",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Emil Holub",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ani Mileva K\u0159emenov\u00e1",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Michaela Krej\u010dov\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-11-20",
        "reservations": [
            {
                "name": "Emil Holub",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ivana Ka\u0161parov\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Dagmar \u010cajkov\u00e1",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Jind\u0159ich Bud\u00edn",
                "time": "14:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-11-21",
        "reservations": [
            {
                "name": "Roman Polinsk\u00fd",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Barbara Souhradov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Vladislav Do\u010dkal",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ivana Ka\u0161parov\u00e1",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Alexandr Bruzl",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Jan Skrbek",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Karel Havl\u00ed\u010dek",
                "time": "10:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-11-22",
        "reservations": [
            {
                "name": "Renata Hrochov\u00e1",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Metod\u011bj Selinger",
                "time": "13:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-11-23",
        "reservations": [
            {
                "name": "Sergiy Koudela",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Michaela Krej\u010dov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Drahom\u00edra Pa\u017adziorov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "V\u00e1clav Graja",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Milena Ridajov\u00e1",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "Jana Kratochv\u00edlov\u00e1",
                "time": "18:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-11-24",
        "reservations": [
            {
                "name": "Emil Holub",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Milan B\u00edlek",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Dana \u010celedov\u00e1",
                "time": "18:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-11-25",
        "reservations": [
            {
                "name": "Jaroslava Pasekov\u00e1",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "V\u00e1clava Rul\u00edkov\u00e1",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jaroslava Pasekov\u00e1",
                "time": "16:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-11-26",
        "reservations": [
            {
                "name": "Daniel Vrba",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Radim Hor\u00e1k",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Franti\u0161ek Jel\u00ednek",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Stanislav \u010c\u00ed\u017eek",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Hynek Ve\u010de\u0159a",
                "time": "08:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-11-27",
        "reservations": [
            {
                "name": "Hynek Ve\u010de\u0159a",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Milan B\u00edlek",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Martina B\u011bhunkov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Josef Lutsak",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Vlasta Hrabov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Miroslav \u0160udoma",
                "time": "14:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-11-28",
        "reservations": [
            {
                "name": "Nikola B\u00ednov\u00e1",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Zden\u011bk Kobliha",
                "time": "16:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-11-29",
        "reservations": [
            {
                "name": "Nad\u011b\u017eda Chalupov\u00e1",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Lenka \u0160vecov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Milan B\u00edlek",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Kristina Star\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Alena Leitnerov\u00e1",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Romana Brou\u0161kov\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Metod\u011bj Selinger",
                "time": "16:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-11-30",
        "reservations": [
            {
                "name": "Quoc Hung V\u00e1vra",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Emil Holub",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jind\u0159ich Bud\u00edn",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ji\u0159ina Drmolov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jaroslav Milda",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Barbara Souhradov\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Tom\u00e1\u0161 \u0160t\u011bp\u00e1n",
                "time": "11:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-12-01",
        "reservations": [
            {
                "name": "Jitka Pruckov\u00e1",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Stefania Davisov\u00e1",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Michaela Krej\u010dov\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-12-02",
        "reservations": [
            {
                "name": "Kamila Vejsadov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "V\u00e1clava Rul\u00edkov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Franti\u0161ek Jel\u00ednek",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Josef Lutsak",
                "time": "13:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-12-03",
        "reservations": [
            {
                "name": "Daniel Vrba",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Stanislav \u010c\u00ed\u017eek",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Miroslav \u0160udoma",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Bo\u017eena Herltov\u00e1",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ladislav Tr\u00e1vn\u00ed\u010dek",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "Kamila Vejsadov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-12-04",
        "reservations": [
            {
                "name": "Veronika Tomkov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ond\u0159ej Robl",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Hynek Ve\u010de\u0159a",
                "time": "18:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-12-05",
        "reservations": [
            {
                "name": "Libor Joh\u00e1nek",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "V\u011bra Makov\u00e1",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Milan B\u00edlek",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ludmila Ruck\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Tereza Kvasni\u010dkov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Alan Kohout",
                "time": "12:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-12-06",
        "reservations": [
            {
                "name": "Helena Kohoutov\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Libor Joh\u00e1nek",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ond\u0159ej Robl",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Quoc Hung V\u00e1vra",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Radim Hor\u00e1k",
                "time": "12:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-12-07",
        "reservations": [
            {
                "name": "Emil Holub",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Martin V\u0161et\u00fd\u010dek",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Hynek Ve\u010de\u0159a",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Kamila Vejsadov\u00e1",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Stanislav \u010c\u00ed\u017eek",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Tereza Kvasni\u010dkov\u00e1",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Barbara Souhradov\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-12-08",
        "reservations": [
            {
                "name": "Petr Br\u016f\u017eek",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Libor Joh\u00e1nek",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Adam Smol\u00edk",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Adam Smol\u00edk",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ji\u0159\u00ed \u010cadil",
                "time": "13:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-12-09",
        "reservations": [
            {
                "name": "Ren\u00e1ta Foksov\u00e1",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Luk\u00e1\u0161 Trnka",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Vladim\u00edr Fatka",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Kristina Star\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-12-10",
        "reservations": [
            {
                "name": "\u0160t\u011bp\u00e1nka Profantov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Lenka \u0160vecov\u00e1",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Robert Maier",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Stanislav \u010c\u00ed\u017eek",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Irena Votavov\u00e1",
                "time": "18:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-12-11",
        "reservations": [
            {
                "name": "Alexandr Bruzl",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Drahom\u00edra Pa\u017adziorov\u00e1",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Dimitrios Ku\u010dera",
                "time": "14:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-12-12",
        "reservations": [
            {
                "name": "Marie Samcov\u00e1",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Luk\u00e1\u0161 Trnka",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Stefania Davisov\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-12-13",
        "reservations": [
            {
                "name": "Adam Smol\u00edk",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Adam Smol\u00edk",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Drahom\u00edra Pa\u017adziorov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Emil Holub",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Pavel Such\u00fd",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ladislav Tr\u00e1vn\u00ed\u010dek",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jan Skrbek",
                "time": "13:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-12-14",
        "reservations": [
            {
                "name": "Ludmila Ruck\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ivana Ka\u0161parov\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Lenka \u0160vecov\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Drahu\u0161e Wro\u017eynov\u00e1",
                "time": "10:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-12-15",
        "reservations": [
            {
                "name": "Jind\u0159ich Bud\u00edn",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Iveta Nowakov\u00e1",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Marek \u0160koln\u00edk",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Drahu\u0161e Wro\u017eynov\u00e1",
                "time": "14:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-12-16",
        "reservations": [
            {
                "name": "Ond\u0159ej Robl",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Tereza Kvasni\u010dkov\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-12-17",
        "reservations": [
            {
                "name": "Ludmila Ruck\u00e1",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Tom\u00e1\u0161 \u0160t\u011bp\u00e1n",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Colin Pla\u010dek",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "Renata Hrochov\u00e1",
                "time": "09:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-12-18",
        "reservations": [
            {
                "name": "Stefania Davisov\u00e1",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jarmila St\u00fctzov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Roman Polinsk\u00fd",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Quoc Hung V\u00e1vra",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Radka Pastrnkov\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Dagmar \u010cajkov\u00e1",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Daniel Vrba",
                "time": "09:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-12-19",
        "reservations": [
            {
                "name": "Dimitrios Ku\u010dera",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "\u0160t\u011bp\u00e1nka Profantov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-12-20",
        "reservations": [
            {
                "name": "Michal Faiereisl",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jaroslav Milda",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jaroslava Pasekov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2024-12-21",
        "reservations": [
            {
                "name": "Anton\u00edn Kutil",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Pavel Such\u00fd",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "V\u011bra Makov\u00e1",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Zd\u011bnka Langerov\u00e1",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Lubom\u00edr Kl\u00edma",
                "time": "11:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-12-22",
        "reservations": [
            {
                "name": "Milan B\u00edlek",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Colin Pla\u010dek",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Vlasta Hrabov\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Colin Pla\u010dek",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Dimitrios Ku\u010dera",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Jaroslava Pasekov\u00e1",
                "time": "14:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-12-23",
        "reservations": [
            {
                "name": "Marie Samcov\u00e1",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Alan Kohout",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Franti\u0161ek Jel\u00ednek",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jind\u0159ich Bud\u00edn",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Renata Hrochov\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-12-24",
        "reservations": [
            {
                "name": "Krist\u00fdna Bajgarov\u00e1",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Natalia \u0160ruba\u0159ov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Iveta Nowakov\u00e1",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Michal Faiereisl",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Miroslav \u0160udoma",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Dimitrios Ku\u010dera",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Quoc Hung V\u00e1vra",
                "time": "18:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-12-25",
        "reservations": [
            {
                "name": "Marek \u0160koln\u00edk",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Natalia \u0160ruba\u0159ov\u00e1",
                "time": "14:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-12-26",
        "reservations": [
            {
                "name": "Vojt\u011bch Brul\u00edk",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Tereza Kvasni\u010dkov\u00e1",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Martin V\u0161et\u00fd\u010dek",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Tom\u00e1\u0161 \u0160t\u011bp\u00e1n",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Barbara Souhradov\u00e1",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ivanka B\u00edlkov\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-12-27",
        "reservations": [
            {
                "name": "Tom\u00e1\u0161 \u0160t\u011bp\u00e1n",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Tereza Kvasni\u010dkov\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ren\u00e1ta Foksov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jakub Bla\u017eek",
                "time": "10:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-12-28",
        "reservations": [
            {
                "name": "Milan B\u00edlek",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Stanislav \u010c\u00ed\u017eek",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Karel Havl\u00ed\u010dek",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jana Kratochv\u00edlov\u00e1",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Michal Faiereisl",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Miroslav \u0160udoma",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Petra Vorlov\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-12-29",
        "reservations": [
            {
                "name": "Kamila Vejsadov\u00e1",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Martin V\u0161et\u00fd\u010dek",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Roman Polinsk\u00fd",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Stefania Davisov\u00e1",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Helena Kohoutov\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ond\u0159ej Robl",
                "time": "16:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2024-12-30",
        "reservations": [
            {
                "name": "Helena Kohoutov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jind\u0159ich Bud\u00edn",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Kamila Vejsadov\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2024-12-31",
        "reservations": [
            {
                "name": "Hana \u0160ikulov\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "\u0160t\u011bp\u00e1nka Profantov\u00e1",
                "time": "16:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-01-01",
        "reservations": [
            {
                "name": "Helena Kohoutov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Alena Leitnerov\u00e1",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Kate\u0159ina \u0160im\u00e1kov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-01-02",
        "reservations": [
            {
                "name": "Lucie Kop\u0159ivov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Drahu\u0161e Wro\u017eynov\u00e1",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Drahom\u00edra Pa\u017adziorov\u00e1",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Barbora Z\u00e1vadov\u00e1",
                "time": "14:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-01-03",
        "reservations": [
            {
                "name": "Daniel Vrba",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Helena Kohoutov\u00e1",
                "time": "08:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-01-04",
        "reservations": [
            {
                "name": "Jakub Bla\u017eek",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ludmila Ruck\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-01-05",
        "reservations": [
            {
                "name": "Eva Va\u0148ov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Martina Jun",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Hynek Ve\u010de\u0159a",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jarmila St\u00fctzov\u00e1",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Tom\u00e1\u0161 \u0160t\u011bp\u00e1n",
                "time": "15:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-01-06",
        "reservations": [
            {
                "name": "Ladislav Tr\u00e1vn\u00ed\u010dek",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Daniel Vrba",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Dimitrios Ku\u010dera",
                "time": "09:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-01-07",
        "reservations": [
            {
                "name": "Vlasta Hrabov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Martin V\u0161et\u00fd\u010dek",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ludmila Ruck\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-01-08",
        "reservations": [
            {
                "name": "Jaroslava Pasekov\u00e1",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Vladislav Do\u010dkal",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Martina Jun",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Karel Havl\u00ed\u010dek",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jana Kratochv\u00edlov\u00e1",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Lubom\u00edr Kl\u00edma",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Luk\u00e1\u0161 Trnka",
                "time": "12:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-01-09",
        "reservations": [
            {
                "name": "Franti\u0161ek Jel\u00ednek",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jana Kratochv\u00edlov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Alan Kohout",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Vlasta Hrabov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ani Mileva K\u0159emenov\u00e1",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Kristina Star\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Roman Polinsk\u00fd",
                "time": "15:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-01-10",
        "reservations": [
            {
                "name": "Michal Faiereisl",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Vladim\u00edr Fatka",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Mat\u011bj Suda",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Hynek Ve\u010de\u0159a",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jan Skrbek",
                "time": "12:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-01-11",
        "reservations": [
            {
                "name": "Veronika Tomkov\u00e1",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jind\u0159ich Bud\u00edn",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Hana \u0160ikulov\u00e1",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Sergiy Koudela",
                "time": "16:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-01-12",
        "reservations": [
            {
                "name": "Zden\u011bk Kobliha",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Alan Kohout",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Tereza Kvasni\u010dkov\u00e1",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Martina B\u011bhunkov\u00e1",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Vlastimil Hl\u00e1vka",
                "time": "13:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-01-13",
        "reservations": [
            {
                "name": "Anton\u00edn Kutil",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Emil Holub",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Lenka \u0160vecov\u00e1",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "David Rou\u0161ar",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Milo\u0161 Vit\u00e1sek",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Martina B\u011bhunkov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ji\u0159\u00ed \u010cadil",
                "time": "13:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-01-14",
        "reservations": [
            {
                "name": "Hynek Ve\u010de\u0159a",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jaroslav Milda",
                "time": "15:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-01-15",
        "reservations": [
            {
                "name": "Jaroslava Pasekov\u00e1",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Helena Kohoutov\u00e1",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Vlastimil Hl\u00e1vka",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Drahu\u0161e Wro\u017eynov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-01-16",
        "reservations": [
            {
                "name": "Lenka \u0160vecov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Lucie Kop\u0159ivov\u00e1",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Lubom\u00edr Kl\u00edma",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Radim Hor\u00e1k",
                "time": "10:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-01-17",
        "reservations": [
            {
                "name": "Zuzana Ku\u010derov\u00e1",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Metod\u011bj Selinger",
                "time": "08:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-01-18",
        "reservations": [
            {
                "name": "Michaela Krej\u010dov\u00e1",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Mat\u011bj Suda",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Zden\u011bk Kobliha",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Dana \u010celedov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Roman Polinsk\u00fd",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Martin V\u0161et\u00fd\u010dek",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Eva Va\u0148ov\u00e1",
                "time": "10:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-01-19",
        "reservations": [
            {
                "name": "Jaroslav Milda",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Roman Polinsk\u00fd",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ren\u00e1ta Foksov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-01-20",
        "reservations": [
            {
                "name": "Barbora Z\u00e1vadov\u00e1",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Lucie Kop\u0159ivov\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "David Rou\u0161ar",
                "time": "14:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-01-21",
        "reservations": [
            {
                "name": "Drahom\u00edra Pa\u017adziorov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Radka Pastrnkov\u00e1",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Stanislav \u010c\u00ed\u017eek",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Barbora Z\u00e1vadov\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Daniel Vrba",
                "time": "18:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-01-22",
        "reservations": [
            {
                "name": "Vojt\u011bch Brul\u00edk",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Zuzana Ku\u010derov\u00e1",
                "time": "18:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-01-23",
        "reservations": [
            {
                "name": "Marek \u0160koln\u00edk",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Nad\u011b\u017eda Chalupov\u00e1",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Marek \u0160koln\u00edk",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Natalia \u0160ruba\u0159ov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Bo\u017eena Herltov\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-01-24",
        "reservations": [
            {
                "name": "Ji\u0159\u00ed \u010cadil",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Eva Va\u0148ov\u00e1",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Alexandr Bruzl",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ji\u0159ina Drmolov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Veronika Tomkov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Romana Brou\u0161kov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Anna Noskov\u00e1",
                "time": "17:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-01-25",
        "reservations": [
            {
                "name": "Kristina Star\u00e1",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Drahu\u0161e Wro\u017eynov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Natalia \u0160ruba\u0159ov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Lucie Kop\u0159ivov\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-01-26",
        "reservations": [
            {
                "name": "Alena Leitnerov\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Michal Faiereisl",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Kamila Vejsadov\u00e1",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Adam Smol\u00edk",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Franti\u0161ek Jel\u00ednek",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Kristina Star\u00e1",
                "time": "13:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-01-27",
        "reservations": [
            {
                "name": "Petra Vorlov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Vlasta Hrabov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Tom\u00e1\u0161 \u0160t\u011bp\u00e1n",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Renata Hrochov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jaroslava Pasekov\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "V\u00e1clava Rul\u00edkov\u00e1",
                "time": "15:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-01-28",
        "reservations": [
            {
                "name": "Marie Samcov\u00e1",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Stanislav \u010c\u00ed\u017eek",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Libu\u0161e Poulov\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Vladim\u00edr Fatka",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ji\u0159\u00ed \u010cadil",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Libor Joh\u00e1nek",
                "time": "08:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-01-29",
        "reservations": [
            {
                "name": "Mat\u011bj Suda",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jaroslav Milda",
                "time": "09:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-01-30",
        "reservations": [
            {
                "name": "Daniel Vrba",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Renata Hrochov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Lucie Kop\u0159ivov\u00e1",
                "time": "11:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-01-31",
        "reservations": [
            {
                "name": "Jitka Pruckov\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Hana \u0160ikulov\u00e1",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ren\u00e1ta Foksov\u00e1",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Anna Noskov\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-02-01",
        "reservations": [
            {
                "name": "Dana \u010celedov\u00e1",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Franti\u0161ek Jel\u00ednek",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Luk\u00e1\u0161 Trnka",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Petra Vorlov\u00e1",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ond\u0159ej Robl",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jind\u0159ich Bud\u00edn",
                "time": "15:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-02-02",
        "reservations": [
            {
                "name": "Ren\u00e1ta Foksov\u00e1",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jarmila St\u00fctzov\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Tereza Kvasni\u010dkov\u00e1",
                "time": "11:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-02-03",
        "reservations": [
            {
                "name": "Marek \u0160koln\u00edk",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ji\u0159\u00ed \u010cadil",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Anton\u00edn Kutil",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Michaela Krej\u010dov\u00e1",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "Miroslav \u0160udoma",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Libu\u0161e Poulov\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-02-04",
        "reservations": [
            {
                "name": "Irena Votavov\u00e1",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Martina B\u011bhunkov\u00e1",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "David Rou\u0161ar",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Natalia \u0160ruba\u0159ov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Juraj Michn\u00edk",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Milo\u0161 Vit\u00e1sek",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jarmila St\u00fctzov\u00e1",
                "time": "14:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-02-05",
        "reservations": [
            {
                "name": "Radka Pastrnkov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Vlasta Hrabov\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ladislav Tr\u00e1vn\u00ed\u010dek",
                "time": "08:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-02-06",
        "reservations": [
            {
                "name": "Quoc Hung V\u00e1vra",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Zuzana Ku\u010derov\u00e1",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Milo\u0161 Vit\u00e1sek",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Zuzana Ku\u010derov\u00e1",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ond\u0159ej Robl",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Karel Havl\u00ed\u010dek",
                "time": "16:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-02-07",
        "reservations": [
            {
                "name": "Colin Pla\u010dek",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Veronika Tomkov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Petra Vorlov\u00e1",
                "time": "17:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-02-08",
        "reservations": [
            {
                "name": "Robert Maier",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Karel Havl\u00ed\u010dek",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Michaela Krej\u010dov\u00e1",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ani Mileva K\u0159emenov\u00e1",
                "time": "13:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-02-09",
        "reservations": [
            {
                "name": "Dimitrios Ku\u010dera",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Vlastimil Hl\u00e1vka",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ren\u00e1ta Foksov\u00e1",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Roman Polinsk\u00fd",
                "time": "17:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-02-10",
        "reservations": [
            {
                "name": "Ladislav Tr\u00e1vn\u00ed\u010dek",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Emil Holub",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "David Rou\u0161ar",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Dana \u010celedov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-02-11",
        "reservations": [
            {
                "name": "Lucie Kop\u0159ivov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Zden\u011bk Kobliha",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Nikola B\u00ednov\u00e1",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Vojt\u011bch Brul\u00edk",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Nad\u011b\u017eda Chalupov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Sergiy Koudela",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Anna Noskov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-02-12",
        "reservations": [
            {
                "name": "Metod\u011bj Selinger",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jarmila St\u00fctzov\u00e1",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Hana \u0160ikulov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Roman Polinsk\u00fd",
                "time": "16:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-02-13",
        "reservations": [
            {
                "name": "Milan B\u00edlek",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Marie Samcov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Drahu\u0161e Wro\u017eynov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-02-14",
        "reservations": [
            {
                "name": "Karel Havl\u00ed\u010dek",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Iveta Nowakov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Alena Leitnerov\u00e1",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Michaela Krej\u010dov\u00e1",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Michal Faiereisl",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Martina B\u011bhunkov\u00e1",
                "time": "08:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-02-15",
        "reservations": [
            {
                "name": "Kate\u0159ina \u0160im\u00e1kov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Kate\u0159ina \u0160im\u00e1kov\u00e1",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ji\u0159\u00ed \u010cadil",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "Vlastimil Hl\u00e1vka",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Iveta Nowakov\u00e1",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jakub Bla\u017eek",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Anton\u00edn Kutil",
                "time": "08:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-02-16",
        "reservations": [
            {
                "name": "Romana Brou\u0161kov\u00e1",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Drahom\u00edra Pa\u017adziorov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-02-17",
        "reservations": [
            {
                "name": "Anna Noskov\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Libor Joh\u00e1nek",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Zuzana Ku\u010derov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jarmila St\u00fctzov\u00e1",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Quoc Hung V\u00e1vra",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Franti\u0161ek Jel\u00ednek",
                "time": "09:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-02-18",
        "reservations": [
            {
                "name": "Romana Brou\u0161kov\u00e1",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Petra Vorlov\u00e1",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jakub Bla\u017eek",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Drahu\u0161e Wro\u017eynov\u00e1",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jan Skrbek",
                "time": "15:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-02-19",
        "reservations": [
            {
                "name": "Ond\u0159ej Robl",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Krist\u00fdna Bajgarov\u00e1",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Alan Kohout",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Lenka \u0160vecov\u00e1",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Jana Kratochv\u00edlov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Iveta Nowakov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-02-20",
        "reservations": [
            {
                "name": "Colin Pla\u010dek",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Metod\u011bj Selinger",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Colin Pla\u010dek",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ren\u00e1ta Foksov\u00e1",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Daniel Vrba",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Anton\u00edn Kutil",
                "time": "15:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-02-21",
        "reservations": [
            {
                "name": "Jan Skrbek",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Miroslav \u0160udoma",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Drahu\u0161e Wro\u017eynov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Alan Kohout",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Martin V\u0161et\u00fd\u010dek",
                "time": "18:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-02-22",
        "reservations": [
            {
                "name": "Vlasta Hrabov\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Nad\u011b\u017eda Chalupov\u00e1",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Jana Kratochv\u00edlov\u00e1",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Sergiy Koudela",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Tereza Kvasni\u010dkov\u00e1",
                "time": "10:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-02-23",
        "reservations": [
            {
                "name": "Jaroslav Milda",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Sergiy Koudela",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Vlastimil Hl\u00e1vka",
                "time": "14:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-02-24",
        "reservations": [
            {
                "name": "Ladislav Tr\u00e1vn\u00ed\u010dek",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Irena Votavov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Vladim\u00edr Fatka",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Petra Vorlov\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "V\u00e1clav Graja",
                "time": "16:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-02-25",
        "reservations": [
            {
                "name": "Vladislav Do\u010dkal",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Eva Va\u0148ov\u00e1",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Krist\u00fdna Bajgarov\u00e1",
                "time": "15:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-02-26",
        "reservations": [
            {
                "name": "Jaroslav Milda",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Daniel Vrba",
                "time": "10:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-02-27",
        "reservations": [
            {
                "name": "Nikola B\u00ednov\u00e1",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Helena Kohoutov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Martin V\u0161et\u00fd\u010dek",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Barbora Z\u00e1vadov\u00e1",
                "time": "11:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-02-28",
        "reservations": [
            {
                "name": "Ji\u0159\u00ed \u010cadil",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Lucie Kop\u0159ivov\u00e1",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Dana \u010celedov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ivanka B\u00edlkov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Libor Joh\u00e1nek",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Bo\u017eena Herltov\u00e1",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Vladim\u00edr Fatka",
                "time": "17:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-03-01",
        "reservations": [
            {
                "name": "Nade\u017eda Barto\u0148ov\u00e1",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jaroslav Milda",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Libor Joh\u00e1nek",
                "time": "14:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-03-02",
        "reservations": [
            {
                "name": "Colin Pla\u010dek",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Romana Brou\u0161kov\u00e1",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Tereza Kvasni\u010dkov\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-03-03",
        "reservations": [
            {
                "name": "Martina B\u011bhunkov\u00e1",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Martina Jun",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Jan Skrbek",
                "time": "15:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-03-04",
        "reservations": [
            {
                "name": "Kristina Star\u00e1",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Barbora Z\u00e1vadov\u00e1",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Barbara Souhradov\u00e1",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Colin Pla\u010dek",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ji\u0159\u00ed \u010cadil",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Barbora Z\u00e1vadov\u00e1",
                "time": "08:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-03-05",
        "reservations": [
            {
                "name": "Ivana Ka\u0161parov\u00e1",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "Dimitrios Ku\u010dera",
                "time": "16:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-03-06",
        "reservations": [
            {
                "name": "Jana Kratochv\u00edlov\u00e1",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Kamila Vejsadov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-03-07",
        "reservations": [
            {
                "name": "Luk\u00e1\u0161 Trnka",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "David Rou\u0161ar",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Tom\u00e1\u0161 \u0160t\u011bp\u00e1n",
                "time": "17:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-03-08",
        "reservations": [
            {
                "name": "Robert Maier",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "\u0160t\u011bp\u00e1nka Profantov\u00e1",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Lubom\u00edr Kl\u00edma",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "Vladislav Do\u010dkal",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Tom\u00e1\u0161 \u0160t\u011bp\u00e1n",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Anna Noskov\u00e1",
                "time": "14:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-03-09",
        "reservations": [
            {
                "name": "V\u00e1clava Rul\u00edkov\u00e1",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Dimitrios Ku\u010dera",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Kate\u0159ina \u0160im\u00e1kov\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ond\u0159ej Robl",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ludmila Ruck\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-03-10",
        "reservations": [
            {
                "name": "Barbora Z\u00e1vadov\u00e1",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Robert Maier",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Renata Hrochov\u00e1",
                "time": "18:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-03-11",
        "reservations": [
            {
                "name": "Jakub Bla\u017eek",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ivanka B\u00edlkov\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Krist\u00fdna Bajgarov\u00e1",
                "time": "10:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-03-12",
        "reservations": [
            {
                "name": "V\u011bra Makov\u00e1",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Daniel Vrba",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Quoc Hung V\u00e1vra",
                "time": "10:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-03-13",
        "reservations": [
            {
                "name": "Veronika Tomkov\u00e1",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Karel Havl\u00ed\u010dek",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Mat\u011bj Suda",
                "time": "11:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-03-14",
        "reservations": [
            {
                "name": "V\u00e1clava Rul\u00edkov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Marek \u0160koln\u00edk",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jaroslav Milda",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Vlastimil Hl\u00e1vka",
                "time": "18:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-03-15",
        "reservations": [
            {
                "name": "Jaroslava Pasekov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Zden\u011bk Kobliha",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Tereza Kvasni\u010dkov\u00e1",
                "time": "09:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-03-16",
        "reservations": [
            {
                "name": "Stanislav \u010c\u00ed\u017eek",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Martina Jun",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Hana \u0160ikulov\u00e1",
                "time": "13:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-03-17",
        "reservations": [
            {
                "name": "Jaroslav Milda",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ivana Ka\u0161parov\u00e1",
                "time": "09:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-03-18",
        "reservations": [
            {
                "name": "Stefania Davisov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jan Skrbek",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ond\u0159ej Robl",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "V\u00e1clav Graja",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Colin Pla\u010dek",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Quoc Hung V\u00e1vra",
                "time": "17:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-03-19",
        "reservations": [
            {
                "name": "Natalia \u0160ruba\u0159ov\u00e1",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Nikola B\u00ednov\u00e1",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Natalia \u0160ruba\u0159ov\u00e1",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Milan B\u00edlek",
                "time": "09:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-03-20",
        "reservations": [
            {
                "name": "Nikola B\u00ednov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Robert Maier",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Robert Maier",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ond\u0159ej Robl",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Anton\u00edn Kutil",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Tereza Kvasni\u010dkov\u00e1",
                "time": "09:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-03-21",
        "reservations": [
            {
                "name": "Roman Polinsk\u00fd",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Dimitrios Ku\u010dera",
                "time": "12:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-03-22",
        "reservations": [
            {
                "name": "Vladislav Do\u010dkal",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Hynek Ve\u010de\u0159a",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Martin V\u0161et\u00fd\u010dek",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Bo\u017eena Herltov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Eva Va\u0148ov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-03-23",
        "reservations": [
            {
                "name": "Drahom\u00edra Pa\u017adziorov\u00e1",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "Bo\u017eena Herltov\u00e1",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Zd\u011bnka Langerov\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Natalia \u0160ruba\u0159ov\u00e1",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ji\u0159\u00ed \u010cadil",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Romana Brou\u0161kov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Karel Havl\u00ed\u010dek",
                "time": "10:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-03-24",
        "reservations": [
            {
                "name": "Alexandr Bruzl",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Vladim\u00edr Fatka",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Milena Ridajov\u00e1",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Juraj Michn\u00edk",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ivanka B\u00edlkov\u00e1",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Dagmar \u010cajkov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-03-25",
        "reservations": [
            {
                "name": "Nad\u011b\u017eda Chalupov\u00e1",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Stefania Davisov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-03-26",
        "reservations": [
            {
                "name": "Ivana Ka\u0161parov\u00e1",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Franti\u0161ek Jel\u00ednek",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Roman Polinsk\u00fd",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jaroslava Pasekov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Jarmila St\u00fctzov\u00e1",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "V\u011bra Makov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "V\u00e1clava Rul\u00edkov\u00e1",
                "time": "18:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-03-27",
        "reservations": [
            {
                "name": "David Rou\u0161ar",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "Alan Kohout",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ani Mileva K\u0159emenov\u00e1",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Jaroslava Pasekov\u00e1",
                "time": "09:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-03-28",
        "reservations": [
            {
                "name": "Zuzana Ku\u010derov\u00e1",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Romana Brou\u0161kov\u00e1",
                "time": "15:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-03-29",
        "reservations": [
            {
                "name": "V\u00e1clav Graja",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Zuzana Ku\u010derov\u00e1",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Veronika Tomkov\u00e1",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ludmila Ruck\u00e1",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Zden\u011bk Kobliha",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Michaela Krej\u010dov\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Karel Havl\u00ed\u010dek",
                "time": "17:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-03-30",
        "reservations": [
            {
                "name": "David Rou\u0161ar",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Tereza Kvasni\u010dkov\u00e1",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ji\u0159ina Drmolov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Milena Ridajov\u00e1",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ladislav Tr\u00e1vn\u00ed\u010dek",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Helena Kohoutov\u00e1",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "Jakub Bla\u017eek",
                "time": "15:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-03-31",
        "reservations": [
            {
                "name": "V\u00e1clava Rul\u00edkov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Zd\u011bnka Langerov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Michal Faiereisl",
                "time": "13:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-04-01",
        "reservations": [
            {
                "name": "Libu\u0161e Poulov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Vladislav Do\u010dkal",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "David Rou\u0161ar",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jana Kratochv\u00edlov\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Kristina Star\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Jitka Pruckov\u00e1",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "V\u00e1clav Graja",
                "time": "15:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-04-02",
        "reservations": [
            {
                "name": "Dimitrios Ku\u010dera",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Krist\u00fdna Bajgarov\u00e1",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Dagmar \u010cajkov\u00e1",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jana Kratochv\u00edlov\u00e1",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "Irena Votavov\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Hynek Ve\u010de\u0159a",
                "time": "13:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-04-03",
        "reservations": [
            {
                "name": "Anton\u00edn Kutil",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Dimitrios Ku\u010dera",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Zuzana Ku\u010derov\u00e1",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Lucie Kop\u0159ivov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-04-04",
        "reservations": [
            {
                "name": "Jind\u0159ich Bud\u00edn",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jitka Pruckov\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-04-05",
        "reservations": [
            {
                "name": "Renata Hrochov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Stefania Davisov\u00e1",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Kate\u0159ina \u0160im\u00e1kov\u00e1",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Barbara Souhradov\u00e1",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Roman Polinsk\u00fd",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Martina B\u011bhunkov\u00e1",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ivana Ka\u0161parov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-04-06",
        "reservations": [
            {
                "name": "Adam Smol\u00edk",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Radim Hor\u00e1k",
                "time": "17:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-04-07",
        "reservations": [
            {
                "name": "Quoc Hung V\u00e1vra",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Hana \u0160ikulov\u00e1",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Metod\u011bj Selinger",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Lenka \u0160vecov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Karel Havl\u00ed\u010dek",
                "time": "18:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-04-08",
        "reservations": [
            {
                "name": "Lenka \u0160vecov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jan Skrbek",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Petr Br\u016f\u017eek",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jind\u0159ich Bud\u00edn",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Dana \u010celedov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-04-09",
        "reservations": [
            {
                "name": "Romana Brou\u0161kov\u00e1",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Petr Br\u016f\u017eek",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Libu\u0161e Poulov\u00e1",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ladislav Tr\u00e1vn\u00ed\u010dek",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Michaela Krej\u010dov\u00e1",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Martina B\u011bhunkov\u00e1",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jakub Bla\u017eek",
                "time": "15:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-04-10",
        "reservations": [
            {
                "name": "Ji\u0159ina Drmolov\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Metod\u011bj Selinger",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Quoc Hung V\u00e1vra",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ren\u00e1ta Foksov\u00e1",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "V\u00e1clava Rul\u00edkov\u00e1",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Metod\u011bj Selinger",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Martin V\u0161et\u00fd\u010dek",
                "time": "15:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-04-11",
        "reservations": [
            {
                "name": "Michaela Krej\u010dov\u00e1",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Daniel Vrba",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Irena Votavov\u00e1",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ani Mileva K\u0159emenov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-04-12",
        "reservations": [
            {
                "name": "Michaela Krej\u010dov\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Petr Br\u016f\u017eek",
                "time": "08:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-04-13",
        "reservations": [
            {
                "name": "Kate\u0159ina \u0160im\u00e1kov\u00e1",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Luk\u00e1\u0161 Trnka",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Juraj Michn\u00edk",
                "time": "09:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-04-14",
        "reservations": [
            {
                "name": "Sergiy Koudela",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Natalia \u0160ruba\u0159ov\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Jitka Pruckov\u00e1",
                "time": "17:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-04-15",
        "reservations": [
            {
                "name": "Drahom\u00edra Pa\u017adziorov\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Petra Vorlov\u00e1",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Pavel Such\u00fd",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Emil Holub",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Tereza Kvasni\u010dkov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Barbara Souhradov\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-04-16",
        "reservations": [
            {
                "name": "Bo\u017eena Herltov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Metod\u011bj Selinger",
                "time": "16:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-04-17",
        "reservations": [
            {
                "name": "Vlasta Hrabov\u00e1",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Nad\u011b\u017eda Chalupov\u00e1",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Kristina Star\u00e1",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Jaroslav Milda",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Marek \u0160koln\u00edk",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Irena Votavov\u00e1",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ren\u00e1ta Foksov\u00e1",
                "time": "15:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-04-18",
        "reservations": [
            {
                "name": "Stanislav \u010c\u00ed\u017eek",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Romana Brou\u0161kov\u00e1",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Martina Jun",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Marek \u0160koln\u00edk",
                "time": "18:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-04-19",
        "reservations": [
            {
                "name": "Bo\u017eena Herltov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ji\u0159ina Drmolov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ji\u0159ina Drmolov\u00e1",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Emil Holub",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Stanislav \u010c\u00ed\u017eek",
                "time": "12:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-04-20",
        "reservations": [
            {
                "name": "Marek \u0160koln\u00edk",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Natalia \u0160ruba\u0159ov\u00e1",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Anna Noskov\u00e1",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Radim Hor\u00e1k",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Milena Ridajov\u00e1",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Michaela Krej\u010dov\u00e1",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Quoc Hung V\u00e1vra",
                "time": "11:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-04-21",
        "reservations": [
            {
                "name": "Libu\u0161e Poulov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Marek \u0160koln\u00edk",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Nad\u011b\u017eda Chalupov\u00e1",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Vlastimil Hl\u00e1vka",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Karel Havl\u00ed\u010dek",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Renata Hrochov\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Vojt\u011bch Brul\u00edk",
                "time": "18:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-04-22",
        "reservations": [
            {
                "name": "\u0160t\u011bp\u00e1nka Profantov\u00e1",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Adam Smol\u00edk",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Alan Kohout",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Petr Br\u016f\u017eek",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Adam Smol\u00edk",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Hynek Ve\u010de\u0159a",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Krist\u00fdna Bajgarov\u00e1",
                "time": "14:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-04-23",
        "reservations": [
            {
                "name": "Ji\u0159ina Drmolov\u00e1",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "V\u00e1clav Graja",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Luk\u00e1\u0161 Trnka",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Krist\u00fdna Bajgarov\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Marie Samcov\u00e1",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Roman Polinsk\u00fd",
                "time": "16:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-04-24",
        "reservations": [
            {
                "name": "Alan Kohout",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Mat\u011bj Suda",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Colin Pla\u010dek",
                "time": "10:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-04-25",
        "reservations": [
            {
                "name": "Jitka Pruckov\u00e1",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Veronika Tomkov\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Marie Samcov\u00e1",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Michal Faiereisl",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Ani Mileva K\u0159emenov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-04-26",
        "reservations": [
            {
                "name": "Dagmar \u010cajkov\u00e1",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Helena Kohoutov\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Bo\u017eena Herltov\u00e1",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Iveta Nowakov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Martin V\u0161et\u00fd\u010dek",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Marek \u0160koln\u00edk",
                "time": "18:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-04-27",
        "reservations": [
            {
                "name": "Jind\u0159ich Bud\u00edn",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Dagmar \u010cajkov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Dimitrios Ku\u010dera",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Miroslav \u0160udoma",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Petra Vorlov\u00e1",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Zd\u011bnka Langerov\u00e1",
                "time": "11:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-04-28",
        "reservations": [
            {
                "name": "Drahu\u0161e Wro\u017eynov\u00e1",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Mat\u011bj Suda",
                "time": "10:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-04-29",
        "reservations": [
            {
                "name": "Lubom\u00edr Kl\u00edma",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Tom\u00e1\u0161 \u0160t\u011bp\u00e1n",
                "time": "08:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jaroslav Milda",
                "time": "11:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-04-30",
        "reservations": [
            {
                "name": "Kristina Star\u00e1",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "Martina Jun",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ivana Ka\u0161parov\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Robert Maier",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Anna Noskov\u00e1",
                "time": "18:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-05-01",
        "reservations": [
            {
                "name": "Jarmila St\u00fctzov\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Drahu\u0161e Wro\u017eynov\u00e1",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Franti\u0161ek Jel\u00ednek",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Lubom\u00edr Kl\u00edma",
                "time": "14:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-05-02",
        "reservations": [
            {
                "name": "Barbora Z\u00e1vadov\u00e1",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Jind\u0159ich Bud\u00edn",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Stanislav \u010c\u00ed\u017eek",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Martina Jun",
                "time": "16:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-05-03",
        "reservations": [
            {
                "name": "V\u00e1clava Rul\u00edkov\u00e1",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Romana Brou\u0161kov\u00e1",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "V\u00e1clav Graja",
                "time": "17:00",
                "court": "Kurt 2"
            },
            {
                "name": "Stanislav \u010c\u00ed\u017eek",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Lucie Kop\u0159ivov\u00e1",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Milo\u0161 Vit\u00e1sek",
                "time": "08:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-05-04",
        "reservations": [
            {
                "name": "Barbara Souhradov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Helena Kohoutov\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Nikola B\u00ednov\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Daniel Vrba",
                "time": "11:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-05-05",
        "reservations": [
            {
                "name": "Lubom\u00edr Kl\u00edma",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Bo\u017eena Herltov\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-05-06",
        "reservations": [
            {
                "name": "V\u00e1clav Graja",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Vojt\u011bch Brul\u00edk",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Barbara Souhradov\u00e1",
                "time": "12:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-05-07",
        "reservations": [
            {
                "name": "Milan B\u00edlek",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Sergiy Koudela",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Sergiy Koudela",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Pavel Such\u00fd",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "V\u011bra Makov\u00e1",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Nikola B\u00ednov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Roman Polinsk\u00fd",
                "time": "12:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-05-08",
        "reservations": [
            {
                "name": "Juraj Michn\u00edk",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Franti\u0161ek Jel\u00ednek",
                "time": "15:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-05-09",
        "reservations": [
            {
                "name": "Ji\u0159ina Drmolov\u00e1",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "V\u00e1clav Graja",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ren\u00e1ta Foksov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Iveta Nowakov\u00e1",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Kristina Star\u00e1",
                "time": "15:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-05-10",
        "reservations": [
            {
                "name": "Milan B\u00edlek",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "Kristina Star\u00e1",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Jitka Pruckov\u00e1",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "Petra Vorlov\u00e1",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Zuzana Ku\u010derov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Vlastimil Hl\u00e1vka",
                "time": "13:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-05-11",
        "reservations": [
            {
                "name": "Juraj Michn\u00edk",
                "time": "13:00",
                "court": "Kurt 2"
            },
            {
                "name": "Drahu\u0161e Wro\u017eynov\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Roman Polinsk\u00fd",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Michaela Krej\u010dov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Emil Holub",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Bob",
                "time": "15:00",
                "court": "Kurt 2 <br><i>(ID GkC3QeKtvQ)</i>"
            }
        ]
    },
    {
        "date": "2025-05-12",
        "reservations": [
            {
                "name": "Iveta Nowakov\u00e1",
                "time": "08:00",
                "court": "Kurt 3"
            },
            {
                "name": "David Rou\u0161ar",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Libor Joh\u00e1nek",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Tereza Kvasni\u010dkov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Anna Noskov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            },
            {
                "name": "Lenka \u0160vecov\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Sergiy Koudela",
                "time": "08:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-05-13",
        "reservations": [
            {
                "name": "Radim Hor\u00e1k",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jitka Pruckov\u00e1",
                "time": "11:00",
                "court": "Kurt 2"
            },
            {
                "name": "Anton\u00edn Kutil",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Lenka \u0160vecov\u00e1",
                "time": "11:00",
                "court": "Kurt 1"
            },
            {
                "name": "Radka Pastrnkov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            },
            {
                "name": "Vlasta Hrabov\u00e1",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Kate\u0159ina \u0160im\u00e1kov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-05-14",
        "reservations": [
            {
                "name": "Jitka Pruckov\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Dagmar \u010cajkov\u00e1",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jakub Bla\u017eek",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Alan Kohout",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jarmila St\u00fctzov\u00e1",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Adam Smol\u00edk",
                "time": "17:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-05-15",
        "reservations": [
            {
                "name": "Ji\u0159\u00ed \u010cadil",
                "time": "14:00",
                "court": "Kurt 3"
            },
            {
                "name": "Jaroslava Pasekov\u00e1",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jind\u0159ich Bud\u00edn",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ladislav Tr\u00e1vn\u00ed\u010dek",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Zd\u011bnka Langerov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ji\u0159ina Drmolov\u00e1",
                "time": "15:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-05-16",
        "reservations": [
            {
                "name": "Vladim\u00edr Fatka",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Veronika Tomkov\u00e1",
                "time": "08:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-05-17",
        "reservations": [
            {
                "name": "Milena Ridajov\u00e1",
                "time": "09:00",
                "court": "Kurt 3"
            },
            {
                "name": "Irena Votavov\u00e1",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "\u0160t\u011bp\u00e1nka Profantov\u00e1",
                "time": "09:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-05-18",
        "reservations": [
            {
                "name": "V\u011bra Makov\u00e1",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Ludmila Ruck\u00e1",
                "time": "10:00",
                "court": "Kurt 3"
            },
            {
                "name": "Roman Polinsk\u00fd",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Dimitrios Ku\u010dera",
                "time": "15:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-05-19",
        "reservations": [
            {
                "name": "Sergiy Koudela",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Zden\u011bk Kobliha",
                "time": "08:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-05-20",
        "reservations": [
            {
                "name": "Petr Br\u016f\u017eek",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "Michal Faiereisl",
                "time": "08:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-05-21",
        "reservations": [
            {
                "name": "Radka Pastrnkov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Vojt\u011bch Brul\u00edk",
                "time": "18:00",
                "court": "Kurt 2"
            },
            {
                "name": "Pavel Such\u00fd",
                "time": "12:00",
                "court": "Kurt 1"
            },
            {
                "name": "David Rou\u0161ar",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ji\u0159\u00ed \u010cadil",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jind\u0159ich Bud\u00edn",
                "time": "11:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-05-22",
        "reservations": [
            {
                "name": "Juraj Michn\u00edk",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Marek \u0160koln\u00edk",
                "time": "09:00",
                "court": "Kurt 1"
            },
            {
                "name": "Drahom\u00edra Pa\u017adziorov\u00e1",
                "time": "15:00",
                "court": "Kurt 3"
            },
            {
                "name": "Kristina Star\u00e1",
                "time": "13:00",
                "court": "Kurt 1"
            },
            {
                "name": "Ond\u0159ej Robl",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Libu\u0161e Poulov\u00e1",
                "time": "12:00",
                "court": "Kurt 2"
            },
            {
                "name": "Libor Joh\u00e1nek",
                "time": "10:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-05-23",
        "reservations": [
            {
                "name": "Vladim\u00edr Fatka",
                "time": "16:00",
                "court": "Kurt 3"
            },
            {
                "name": "Veronika Tomkov\u00e1",
                "time": "14:00",
                "court": "Kurt 1"
            },
            {
                "name": "Radka Pastrnkov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-05-24",
        "reservations": [
            {
                "name": "Pavel Such\u00fd",
                "time": "18:00",
                "court": "Kurt 3"
            },
            {
                "name": "Martina Jun",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Nad\u011b\u017eda Chalupov\u00e1",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Vojt\u011bch Brul\u00edk",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Martina B\u011bhunkov\u00e1",
                "time": "17:00",
                "court": "Kurt 1"
            },
            {
                "name": "Marie Samcov\u00e1",
                "time": "10:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-05-25",
        "reservations": [
            {
                "name": "Barbora Z\u00e1vadov\u00e1",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Robert Maier",
                "time": "14:00",
                "court": "Kurt 2"
            },
            {
                "name": "Jakub Bla\u017eek",
                "time": "09:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-05-26",
        "reservations": [
            {
                "name": "Vladim\u00edr Fatka",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Nad\u011b\u017eda Chalupov\u00e1",
                "time": "13:00",
                "court": "Kurt 3"
            },
            {
                "name": "Renata Hrochov\u00e1",
                "time": "15:00",
                "court": "Kurt 2"
            },
            {
                "name": "Petra Vorlov\u00e1",
                "time": "10:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-05-27",
        "reservations": [
            {
                "name": "Daniel Vrba",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Daniel Vrba",
                "time": "18:00",
                "court": "Kurt 1"
            },
            {
                "name": "Colin Pla\u010dek",
                "time": "12:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-05-28",
        "reservations": [
            {
                "name": "Irena Votavov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Roman Polinsk\u00fd",
                "time": "12:00",
                "court": "Kurt 3"
            },
            {
                "name": "Alena Leitnerov\u00e1",
                "time": "17:00",
                "court": "Kurt 3"
            },
            {
                "name": "Hynek Ve\u010de\u0159a",
                "time": "16:00",
                "court": "Kurt 2"
            },
            {
                "name": "Libor Joh\u00e1nek",
                "time": "16:00",
                "court": "Kurt 2"
            }
        ]
    },
    {
        "date": "2025-05-29",
        "reservations": [
            {
                "name": "Kamila Vejsadov\u00e1",
                "time": "09:00",
                "court": "Kurt 2"
            },
            {
                "name": "Mat\u011bj Suda",
                "time": "17:00",
                "court": "Kurt 3"
            }
        ]
    },
    {
        "date": "2025-05-30",
        "reservations": [
            {
                "name": "Bo\u017eena Herltov\u00e1",
                "time": "15:00",
                "court": "Kurt 1"
            },
            {
                "name": "Nade\u017eda Barto\u0148ov\u00e1",
                "time": "13:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-05-31",
        "reservations": [
            {
                "name": "Jana Kratochv\u00edlov\u00e1",
                "time": "16:00",
                "court": "Kurt 1"
            },
            {
                "name": "Jind\u0159ich Bud\u00edn",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Luk\u00e1\u0161 Trnka",
                "time": "09:00",
                "court": "Kurt 1"
            }
        ]
    },
    {
        "date": "2025-06-01",
        "reservations": [
            {
                "name": "Jaroslava Pasekov\u00e1",
                "time": "10:00",
                "court": "Kurt 2"
            },
            {
                "name": "Pavel Such\u00fd",
                "time": "11:00",
                "court": "Kurt 3"
            },
            {
                "name": "Adam Smol\u00edk",
                "time": "11:00",
                "court": "Kurt 2"
            }
        ]
    }
]