import React from "react";

type PriceListItem = {
  category: string;
  price: string;
};

const priceList: PriceListItem[] = [
  { category: "Jednorázový vstup (dospělý)", price: "300 Kč" },
  { category: "Jednorázový vstup (dítě)", price: "200 Kč" },
  { category: "Měsíční členství (dospělý)", price: "1 500 Kč" },
  { category: "Měsíční členství (dítě)", price: "1 000 Kč" },
  { category: "Pronájem kurtu (hodina)", price: "400 Kč" },
  { category: "Individuální lekce s trenérem", price: "600 Kč" },
];

const PriceList: React.FC = () => {
  return (
    <div style={{ padding: "20px", maxWidth: "600px", margin: "0 auto" }}>
      <h2>Ceník</h2>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={{ textAlign: "left", borderBottom: "2px solid #ccc", padding: "10px" }}>Kategorie</th>
            <th style={{ textAlign: "right", borderBottom: "2px solid #ccc", padding: "10px" }}>Cena</th>
          </tr>
        </thead>
        <tbody>
          {priceList.map((item, index) => (
            <tr key={index}>
              <td style={{ padding: "10px", borderBottom: "1px solid #eee" }}>{item.category}</td>
              <td style={{ padding: "10px", textAlign: "right", borderBottom: "1px solid #eee" }}>{item.price}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PriceList;
