function Homepage() {
    return ( <>

    <section className="hero">
        <h1>SK Záhonek Šumplerk</h1>
        <p>tenisový klub</p>
    </section>

    <section className="section about" style={{height: "55vh", display: "flex", flexDirection: "column" ,alignItems: "center"}}>
        <h2>O nás</h2>
        <p style={{width: "70vw"}}>Tenisové centrum SK Záhonek Šumplerk je moderní sportovní areál, který se zaměřuje na rozvoj tenisového talentu všech věkových kategorií. Nacházíme se v krásném a klidném prostředí Šumplerka, kde nabízíme špičkové zázemí jak pro rekreační hráče, tak pro ambiciózní profesionály. Naším cílem je vytvořit komunitu tenisových nadšenců, kteří si užívají nejen sportovní soutěžení, ale i radost ze hry a zdravý životní styl.
            <br/><br/>
            Disponujeme několika kvalitními tenisovými kurty s antukovým i tvrdým povrchem, které jsou udržovány na nejvyšší úrovni po celý rok. Kromě toho nabízíme i krytou halu pro hru v zimních měsících, což umožňuje našim hráčům trénovat za každého počasí. Součástí centra je i moderní klubovna, kde si můžete odpočinout, občerstvit se a sdílet zážitky s ostatními hráči. Naše prostory jsou navrženy tak, aby poskytovaly pohodlí a inspirativní atmosféru pro všechny návštěvníky.
            <br/><br/>
            Naše zkušený tým trenérů se věnuje nejen výuce základů tenisu, ale také pokročilým technikám a strategii pro soutěžní hráče. Nabízíme individuální i skupinové lekce, dětské tenisové kroužky a organizujeme turnaje a tenisové kempy. SK Záhonek Šumplerk je místem, kde se setkává vášeň pro tenis s přátelskou komunitou a profesionálním přístupem. Přijďte si zahrát a objevte kouzlo tohoto krásného sportu v našem centru!</p>
    </section>

    </>);
}

export default Homepage;